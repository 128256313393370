const pt = {
  translations:
  {

    "language>currentLanguageDesc": "Português",
    "language>switchTo": "Español",
    "language>switchToIcon": "es",
    "language>currentLanguageShort": "pt",

    "login>paragraph>document": "Para começar a digitar seu ID e senha.",
    "login>label>password": "Senha",
    "login>button>into": "Entrar",
    "login>title>o": "ou",
    "login>title>loginVrio": "Login in Vrio (DIRECTV | SKY)",
    "login>button>loginGuest": "Login como convidado",

    "home>title>welcome": "Bem vindo/a!",
    "home>title>pass": "Você deve fazer uma mudança de senha antes de continuar!",
    "home>h1>br1": "Safedesk é uma ferramenta que permite que voce reserve um local para trabalhar nos nossos escritórios. Desta forma, garantimos a conformidade com os protocolos para uma permanência segura e organizada.",
    "home>h1>br2": "Através do SafeDesk, você pode agendar sua reserva de mesa especificando a data e a hora antecipadamente.",
    "home>h1>br3": "Siga estes conselhos e regras de prevenção para cuidar uns dos outros",
    "home>a>turn": "Reserve novo turno.",

    "navbar>button>diagnosis": "Diagnóstico automático.",
    "navbar>button>turn": "Agende",
    "navbar>button>reservation": "Minhas reservas",
    "navbar>button>reports": "Relatórios",
    "navbar>button>management": "Gestão",
    "navbar>button>abms": "ABMs",
    "navbar>button>singOff": "Sair",
    "navbar>button>listadoturnos": "reservacones",
    "navbar>button>vacunaciones": "vacinações",

    "navbar>link>report": "INCIDENTE DE RELATÓRIO.",

    "diagnostico>title>vaccination": "Vacinação:",
    "diagnostico>label>vaccinated": "Eu sou vacinado",
    "diagnostico>label>doses": "Eu apliquei as duas doses da vacina",
    "diagnostico>bold>vaccinate": "Com qual vacina?",
    "diagnostico>label>yesVaccinate": "Eu quero vacinar",
    "diagnostico>label>notVaccinate": "Eu não quero vacinar",
    "diagnostico>label>infoUpdate": "A informação está atualizada",
    "diagnostico>title>symptoms": "Sintomas:",
    "diagnostico>label>temperature": "Eu tenho temperatura maior que 37 °",
    "diagnostico>label>taste": "Eu tenho perda de gosto",
    "diagnostico>label>contactCovid": "Eu tinha contato próximo com Covid",
    "diagnostico>label>contactCercanoExperta": "Você esteve em contato com alguém com diagnóstico confirmado ou suspeito de COVID-19 (independentemente da distância ou uso ou não de máscara)?",
    "diagnostico>label>contactCercanoDirectv": "Você teve contato próximo (por exemplo, um abraço) ou (a menos de 2 metros, por pelo menos 15 minutos, em ambiente fechado sem máscara) nos últimos 7 dias com uma pessoa diagnosticada com COVID-19 ou que segue o protocolo COVID-19?",
    "diagnostico>label>sintomasGrupo":"Você tem algum dos sintomas mencionados abaixo?",
    "diagnostico>title>existing": "Condições pré-existentes:",
    "diagnostico>label>pregnant": "Estou grávida",
    "diagnostico>label>cancer": "Eu tenho / tinha câncer",
    "diagnostico>label>diabetes": "Eu tenho diabetes.",
    "diagnostico>label>disease": "Eu tenho alguma doença hepática",
    "diagnostico>label>lossSmell": "Eu tenho uma perda de cheiro",
    "diagnostico>label>soreThroat": "Tenho dor de garganta",
    "diagnostico>label>breathingDifficulty": "Eu tenho dificuldade de respiração",
    "diagnostico>label>notSynptom": "Eu não tenho nenhum sintoma",
    "diagnostico>button>sendDiagnostic": "Enviar autodiagnóstico.",
    "diagnostico>button>indicarVacunas": "Indicar vacinas",
    "diagnostico>text>vacunasCargadas": "Vacinas carregadas no sistema",
    "diagnostico>text>verificarInfo": "Por favor, verifique as informações.",
    "diagnostico>btn>nuevaVacuna": "Nova vacina",
    "diagnostico>label>temp": "Temperatura de 37,5°C",
    "diagnostico>label>tos": "Tosse",
    "diagnostico>label>garganta": "Dor de garganta",
    "diagnostico>label>nasal": "Congestão nasal ou coriza",
    "diagnostico>label>agitacion": "Agitação ou sensação de falta de ar",
    "diagnostico>label>olfato": "Perda de olfato",
    "diagnostico>label>gusto": "Gosto diminuído",
    "diagnostico>label>diarrea": "Diarréia ou vômito",
    "diagnostico>label>cabeza": "Dor de cabeça",
    "diagnostico>label>musculares": "Dores musculares",
    "diagnostico>title>editarVacuna": "Editar vacina",
    "diagnostico>title>guardarVacuna": "Nova vacina",
    "diagnostico>btn>cargarImagen": "Enviar Imagem",
    "diagnostico>label>selectVacuna": "Selecione a vacina...",

    "reserva>nombre>building": "Selecione o escritorio",
    "reserva>horario>building": "Selecione o escritorio",
    "reserva>title>warning": "Aviso",
    "reserva>text>selfDiagnostic": "Voce deve realizar o auto-diagnostico para fazer uma reserva",
    "reserva>title>turn": "Faça uma reserva",
    "reserva>paragraph>reserve": "Selecione uma data e um escritorio para reservar!",
    "reserva>titlelabel>choseDate": "Eu escolhi a data",
    "reserva>titlelabel>business": "Companhia",
    "reserva>titlelabel>managfement": "Gestão ou tipo de espaço",
    "reserva>titlelabel>site": "Escolha o escritorio",
    "reserva>titlelabel>collaborate": "Escolha o colaborador",
    "reserva>titlelabel>floor": "Escolha o andar",
    "reserva>titlelabel>schedule": "Escolha o horario",
    "reserva>label>parking": "Reserve com estacionamento",
    "reserva>button>confirm": "confirme",
    "reserva>button>soloestacionamiento": "Apenas estacionamento",
    "reserva>button>reservaOficinas": "Escritórios",
    "reserva>titlelabel>permisoEstacionamiento":"você não tem permissão de estacionamento",

    "misReserva>title>qrGenerated": "QR gerado, lembre-se de fazer autodiagnóstico!",
    "misReserva>delete>turnDeleted": "Reserva excluída corretamente!",
    "misReserva>text>yes": "sim",
    "misReserva>text>no": "Não",
    "misReserva>text>date": "Data",
    "misReserva>text>site": "Escritorio",
    "misReserva>text>schedule": "Horario",
    "misReserva>text>sospechoso": "Foi detectado um diagnóstico suspeito de covid",
    "misReserva>text>diagVencido": "Você expirou o autodiagnóstico",
    "misReserva>text>permission": "Sem permissões na câmera do dispositivo você não pode digitalizar QR",
    "misReserva>title>reservations": "Minhas reservas",
    "misReserva>button>qrIncome": "Gerar QR de entrada",
    "misReserva>button>qrScan": "Digitalizar estacionamento qr",
    "misReserva>button>qrValidarReserva": "Digitalizar QR",
    "misReserva>paragraph>activeReserver": "Reservas ativas",
    "misReserva>paragraph>historialReserver": "Reservas históricas",
    "misReserva>paragraph>permissionLeyend": "*Lembre-se de verificar as configurações do seu dispositivo para garantir que os privilégios da câmera estejam ativos",
    
    "reportes>title>report": "Relatório",
    "reportes>title>reportSelect": "Selecione um relatório.",
    "reportes>name>everyone": "Todo o mundo",
    "reportes>name>report": "Selecione Relatório.",
    "reportes>name>building": "Selecione o site",
    "reportes>hours>building": "Selecione o site",
    "reportes>value>management": "gestão",
    "reportes>value>username": "Nome do usuário",
    "reportes>value>edifice": "Local",
    "reportes>value>floor": "Andar",
    "reportes>value>schedule": "Horario",
    "reportes>value>date": "Data",
    "reportes>label>choseReport": "Escolha o relatório",
    "reportes>label>cheseDate": "Escolha a data",
    "reportes>label>choseManagement": "Escolha Área",
    "reportes>label>choseCollaborator": "Escolha o colaborador",
    "reportes>label>choseSite": "Escolha o escritorio",
    "reportes>label>choseFloor": "Escolha o andar",
    "reportes>label>choseSchedule": "Escolha o horario",
    "reportes>button>confirm": "confirme",

    "administracion>title>management": "Gestão:",
    "administracion>label>eneableTurn": "Ativar reserva de reservas",
    "administracion>label>eneableDiagnosis": "Ativar autodiagnóstico",
    "administracion>button>saveChanges": "Salvar",

    "invitados>alert>notSymptom": "Você não pode ter um sintoma e escolher a opção sem sintomas!",
    "invitados>alert>selectSymptom": "Você deve selecionar uma opção nos sintomas!",
    "invitados>title>guests": "Convidados",
    "invitados>alert>sentDiagnosis": "Diagnóstico enviado corretamente!",
    "invitados>label>selectCompany": "Escolha uma empresa:",
    "invitados>textHelper>selectOption": "Por favor selecione uma opção",
    "invitados>placeholder>surnameName": "Sobrenome e Nomes.",
    "invitados>placeholder>email": "E-mail",
    "invitados>placeholder>companyCode": "Código de escritorio",
    "invitados>title>autoDiagnosis": "Diagnóstico automático.",
    "invitados>title>vaccination": "Vacinação:",
    "invitados>label>iAmVaccinated": "Eu sou vacinado",
    "invitados>label>dosesVaccine": "Recebi as duas doses da vacina",
    "invitados>label>whatVaccine": "Com o que vacina?",
    "invitados>label>wantVaccine": "Eu quero ser vacinado",
    "invitados>label>notVaccine": "Eu não quero ser vacinado",
    "invitados>title>symptoms": "Sintomas:",
    "invitados>label>temperature": "Eu tenho temperatura maior que 37 °",
    "invitados>label>lossTaste": "Eu tenho perda de gosto",
    "invitados>label>contactCovid": "Eu tinha contato próximo com Covid",
    "invitados>label>pregnant": "Estou grávida",
    "invitados>label>cancer": "Eu tenho / tinha câncer",
    "invitados>label>diabetes": "Eu tenho diabetes.",
    "invitados>label>disease": "Eu tenho alguma doença hepática",
    "invitados>label>lossSmell": "Eu tenho uma perda de cheiro",
    "invitados>label>soreThroat": "Tenho dor de garganta",
    "invitados>label>breathingDifficulty": "Eu tenho dificuldade de respiração",
    "invitados>label>notSymptom": "Eu não tenho nenhum sintoma",
    "invitados>button>send": "Enviar",

    "listadoDeTurnos>title>list": "Lista de reservas.",
    "listadoDeTurnos>label>date": "Eu escolhi a data",
    "listadoDeTurnos>option>select": "Selecione a construção ...",
    "listadoDeTurnos>header>user": "Do utilizador.",
    "listadoDeTurnos>header>schedule": "Cronograma",
    "listadoDeTurnos>header>edifice": "Buildo.",
    "listadoDeTurnos>header>data": "Data mostrada.",
    "listadoDeTurnos>header>scanning": "Digitalização",

    "listadoVacunaciones>header>dni": "DNI.",
    "listadoVacunaciones>header>user": "Do utilizador.",
    "listadoVacunaciones>header>vaccinated": "Vacinado.",
    "listadoVacunaciones>header>dosis": "Dosdose.",
    "listadoVacunaciones>header>vaccined": "Vacina",
    "listadoVacunaciones>header>mail": "E-mail",
    "listadoVacunaciones>title>notvaccinated": "Não vacinado.",
    "listadoVacunaciones>title>vaccinated": "Vacinado.",

    "abms>title>users": "Comercial",
    "abms>title>build": "Edifícios",
    "abms>title>vaccines": "Vacinas",
    "abms>title>management": "Gestão",
    "abms>title>types": "Tipos de usuário.",
    "abms>title>countries": "Países.",

    "usuarios>title>users": "Comercial",
    "usuarios>title>newUsers": "Novo usuário.",
    "usuarios>header>dni": "DNI.",
    "usuarios>header>name": "inhame",

    "edificios>title>building": "Edifícios",
    "edificios>button>newBuilding": "Nova construção.",
    "edificios>header>id": "identificação",
    "edificios>header>usersName": "inhame",
    "edificios>header>Direction": "Direção",
    "edificios>header>parking": "Com estacionamento",
    "edificios>header>quotaParking": "Estacionamento da cota",

    "vacunas>title>vaccines": "Vacinas",
    "vacunas>title>vaccine": "vacina",
    "vacunas>headers>id": "identificação",
    "vacunas>headers>name": "inhame",

    "gerencia>title>management": "Gestão",
    "gerencia>label>management": "Gerenciamento de nome",
    "gerencia>placeholder>name": "Insira o nome ...",
    "gerencia>option>company": "Selecione empresa ...",
    "gerencia>button>changes": "Salvar alterações.",
    "gerencia>button>confirm": "confirme",
    "gerencia>title>delete": "Tem certeza de que deseja excluir?",
    "gerencia>text>reverse": "Você não será capaz de inverter as mudanças",
    "gerencia>button>yes": "E isso é",
    "gerencia>button>newManagement": "Nova gestão",
    "gerencia>header>id": "identificação",
    "gerencia>header>name": "inhame",
    "gerencia>header>ideempresa": "IDE.",

    "tiposDeUsuario>title>typeUser": "Tipos de usuário.",
    "tiposDeUsuario>label>description": "Descrição.",
    "tiposDeUsuario>placeholder>enterDescription": "Digite a descrição ...",
    "tiposDeUsuario>button>confirm": "confirme",
    "tiposDeUsuario>button>changes": "Salvar alterações.",
    "tiposDeUsuario>button>buttonConfirm": "confirme",
    "tiposDeUsuario>title>delete": "Tem certeza de que deseja excluir?",
    "tiposDeUsuario>text>reverse": "Você não será capaz de inverter as mudanças",
    "tiposDeUsuario>button>yes": "E isso é",
    "tiposDeUsuario>title>typesUser": "Tipos de usuário.",
    "tiposDeUsuario>button>kind": "Novo tipo.",
    "tiposDeUsuario>header>description": "Descrição.",
    "tiposDeUsuario>title>newType": "Crie novo tipo de usuário",

    "paises>title>countries": "Países.",
    "paises>label>nameCountry": "Nome do país.",
    "paises>placeholder>enterName": "Insira o nome ...",
    "paises>button>confirm": "confirme",
    "paises>header>name": "inhame",
    "paises>title>create": "Criar novo país.",

    "button>text>back": "Voltar",
    "ASIGNADO": "espaço alocado",
    "CERRADO": "espaço fechado",
    "ABIERTO": "espaço aberto",
    "turnospordia>title>list": "Turnos por dia",
    "turnospordia>button>reports": "Relatórios"
  }
};

export default pt;
