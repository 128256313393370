const en = {
  translations: {
    "language>currentLanguageDesc": "English",
    "language>switchTo": "Español",
    "language>switchToIcon": "es",
    "language>currentLanguageShort": "en",

      "language>currentLanguageDesc": "English",
      "language>switchTo": "Español",
      "language>switchToIcon": "es",
      "language>currentLanguageShort": "en",
      
      "login>paragraph>document": "To start enter your ID and password.",
      "login>label>password": "Password",
      "login>button>into": "Get into",
      "login>title>o": "or",
      "login>title>loginVrio": "Login in Vrio (DIRECTV | SKY)",
      "login>button>loginGuest": "Login as guest",
      
      "home>title>welcome": "Welcome!",
      "home>title>pass": "You must change your password to continue!",
      "home>h1>br1": "SafeDesk is a tool that allows you to schedule a reserve for a work space for the days you go to the office. In this way, we ensure compliance with the protocols for a safe and orderly stay.",
      "home>h1>br2": "Through SafeDesk you can schedule your desk reservation specifying date and time beforehand.",
      "home>h1>br3": "Follow these advices and prevention rules to take care of each other.",
      "home>a>turn": "Schedule new reserve",
      
      "navbar>button>diagnosis": "Auto diagnosis",
      "navbar>button>turn": "Schedule",
      "navbar>button>reservation": "My reservations",
      "navbar>button>reports": "Reports",
      "navbar>button>management": "Management",
      "navbar>button>abms": "ABMS.",
      "navbar>button>singOff": "Log Out",
      "navbar>button>listadoturnos": "reservations",
      "navbar>button>vacunaciones": "vaccinations",
      
      "navbar>link>report": "Report incident",
      
      "diagnostico>title>vaccination": "Vaccination:",
      "diagnostico>label>vaccinated": "I am vaccinated",
      "diagnostico>label>doses": "I received the 2 doses of the vaccine",
      "diagnostico>bold>vaccinate": "With what vaccine?",
      "diagnostico>label>yesVaccinate": "I want to vaccinate",
      "diagnostico>label>notVaccinate": "I do not want to vaccinate",
      "diagnostico>label>infoUpdate": "The information is updated",
      "diagnostico>title>symptoms": "Symptoms:",
      "diagnostico>label>temperature": "I have temperature greater than 37 °",
      "diagnostico>label>taste": "I have taste loss",
      "diagnostico>label>contactCovid": "I had close contact with Covid",
      "diagnostico>label>contactCercanoExperta": "Have you been in contact with someone with a confirmed or suspected diagnosis of COVID-19 (regardless of distance or use or not of a mask)?",
      "diagnostico>label>contactCercanoDirectv": "Have you had close contact (for example, a hug) or (within 2 meters, for at least 15 minutes, in a closed environment without a mask) in the last 7 days with a person who has been diagnosed with COVID-19 or that follows the COVID-19 protocol?",
      "diagnostico>label>sintomasGrupo":"Do you have any of the symptoms mentioned below?",
      "diagnostico>title>existing": "Pre-existing conditions:",
      "diagnostico>label>pregnant": "I'm pregnant",
      "diagnostico>label>cancer": "I have / had cancer",
      "diagnostico>label>diabetes": "I have diabetes",
      "diagnostico>label>disease": "I have some liver disease",
      "diagnostico>label>lossSmell": "I have smell loss",
      "diagnostico>label>soreThroat": "I have a sore throat",
      "diagnostico>label>breathingDifficulty": "I have breathing difficulty",
      "diagnostico>label>notSynptom": "I do not have any symptom",
      "diagnostico>button>sendDiagnostic": "Send self-diagnostico.",
      "diagnostico>button>indicarVacunas": "Indicate vaccines",
      "diagnostico>text>vacunasCargadas": "Vaccines loaded into the system",
      "diagnostico>text>verificarInfo": "Please verify information.",
      "diagnostico>btn>nuevaVacuna": "New vaccine",
      "diagnostico>label>temp": "Temperature of 37.5°C",
      "diagnostico>label>tos": "Cough",
      "diagnostico>label>garganta": "Throat pain",
      "diagnostico>label>nasal": "Nasal congestion or runny nose",
      "diagnostico>label>agitacion": "Agitation or feeling short of breath",
      "diagnostico>label>olfato": "Loss of smell",
      "diagnostico>label>gusto": "Decreased taste",
      "diagnostico>label>diarrea": "Diarrhea or vomiting",
      "diagnostico>label>cabeza": "Headache",
      "diagnostico>label>musculares": "Muscle pains",
      "diagnostico>title>editarVacuna": "Edit vaccine",
      "diagnostico>title>guardarVacuna": "New vaccine",
      "diagnostico>btn>cargarImagen": "upload image",
      "diagnostico>label>selectVacuna": "Select vaccine...",
      
      "reserva>nombre>building": "Select building",
      "reserva>horario>building": "Select building",
      "reserva>title>warning": "Warning",
      "reserva>text>selfDiagnostic": "You must do the self-diagostic to be able to schedule a reserve.",
      "reserva>title>turn": "Schedule your reservation",
      "reserva>paragraph>reserve": "Select a date and a site to reserve!",
      "reserva>titlelabel>choseDate": "Choose the date",
      "reserva>titlelabel>business": "Company",
      "reserva>titlelabel>managfement": "Management or type of space",
      "reserva>titlelabel>site": "Choose the site",
      "reserva>titlelabel>collaborate": "Choose the colaborator",
      "reserva>titlelabel>floor": "Choose the floor number",
      "reserva>titlelabel>schedule": "Choose time",
      "reserva>label>parking": "Book with parking",
      "reserva>button>confirm": "Confirm",
      "reserva>button>soloestacionamiento": "Parking only",
      "reserva>button>reservaOficinas": "Reserve Offices",
      "reserva>titlelabel>permisoEstacionamiento":"You don't have parking permits",

      "misReserva>title>qrGenerated": "QR generated, remember to make self-diagnosis!",
      "misReserva>delete>turnDeleted": "Reservation deleted correctly!",
      "misReserva>text>yes": "Yes",
      "misReserva>text>no": "No",
      "misReserva>text>date": "Date:",
      "misReserva>text>site": "Site:",
      "misReserva>text>schedule": "Time:",
      "misReserva>text>sospechoso": "A suspected diagnosis of covid has been detected",
      "misReserva>text>diagVencido": "You have expired self-diagnosis",
      "misReserva>text>permission": "Without permissions on the device camera you cannot scan QR",
      "misReserva>title>reservations": "My reservations",
      "misReserva>button>qrIncome": "Generate access QR",
      "misReserva>button>qrScan": "Scan parking qr",
      "misReserva>button>qrValidarReserva": "Scan QR",
      "misReserva>paragraph>activeReserver": "Active reserves",
      "misReserva>paragraph>historialReserver": "Historical reserves",
      "misReserva>paragraph>permissionLeyend": "*Remember to check your device settings to make sure camera privileges are active",

      "reportes>title>report": "Report",
      "reportes>title>reportSelect": "Select a report.",
      "reportes>name>everyone": "Everyone",
      "reportes>name>report": "Select report.",
      "reportes>name>building": "Select Site",
      "reportes>hours>building": "Select Site",
      "reportes>value>management": "management",
      "reportes>value>username": "Username",
      "reportes>value>edifice": "Site",
      "reportes>value>floor": "floor",
      "reportes>value>schedule": "Time",
      "reportes>value>date": "date",
      "reportes>label>choseReport": "Choose report",
      "reportes>label>cheseDate": "Choose date",
      "reportes>label>choseManagement": "Choose Area",
      "reportes>label>choseCollaborator": "Choose colaborator",
      "reportes>label>choseSite": "Choose site",
      "reportes>label>choseFloor": "Choose Floor",
      "reportes>label>choseSchedule": "Choose time",
      "reportes>button>confirm": "Confirm",
      
      "administracion>title>management": "Management:",
      "administracion>label>eneableTurn": "Enable Reservations",
      "administracion>label>eneableDiagnosis": "Enable self-diagnosis",
      "administracion>button>saveChanges": "Save Changes",
      
      "invitados>alert>notSymptom": "You can not have a symptom and choose the option without symptoms!",
      "invitados>alert>selectSymptom": "You must select an option in symptoms!",
      "invitados>title>guests": "Guests",
      "invitados>alert>sentDiagnosis": "Diagnosis sent correctly!",
      "invitados>label>selectCompany": "Choose a company:",
      "invitados>textHelper>selectOption": "Please select an option",
      "invitados>placeholder>surnameName": "Surname and names",
      "invitados>placeholder>email": "E-mail",
      "invitados>placeholder>companyCode": "Building code",
      "invitados>title>autoDiagnosis": "Auto diagnosis",
      "invitados>title>vaccination": "Vaccination:",
      "invitados>label>iAmVaccinated": "I am vaccinated",
      "invitados>label>dosesVaccine": "I received the 2 doses of the vaccine",
      "invitados>label>whatVaccine": "With what vaccine?",
      "invitados>label>wantVaccine": "I want to get vaccinated",
      "invitados>label>notVaccine": "I do not want to get vaccinated",
      "invitados>title>symptoms": "Symptoms:",
      "invitados>label>temperature": "I have temperature greater than 37 °",
      "invitados>label>lossTaste": "I have taste loss",
      "invitados>label>contactCovid": "I had close contact with Covid",
      "invitados>label>pregnant": "I'm pregnant",
      "invitados>label>cancer": "I have / had cancer",
      "invitados>label>diabetes": "I have diabetes",
      "invitados>label>disease": "I have some liver disease",
      "invitados>label>lossSmell": "I have smell loss",
      "invitados>label>soreThroat": "I have sore throat",
      "invitados>label>breathingDifficulty": "I have a breathing difficulty",
      "invitados>label>notSymptom": "I do not have any symptom",
      "invitados>button>send": "Send",
      
      "listadoDeTurnos>title>list": "List of shifts",
      "listadoDeTurnos>label>date": "I chose the date",
      "listadoDeTurnos>option>select": "Select building ...",
      "listadoDeTurnos>header>user": "User",
      "listadoDeTurnos>header>schedule": "Schedule",
      "listadoDeTurnos>header>edifice": "Edifice",
      "listadoDeTurnos>header>data": "Date shown",
      "listadoDeTurnos>header>scanning": "Scanning",
      
      "listadoVacunaciones>header>dni": "DNI.",
      "listadoVacunaciones>header>user": "User",
      "listadoVacunaciones>header>vaccinated": "Vaccinated",
      "listadoVacunaciones>header>dosis": "Dosdosis",
      "listadoVacunaciones>header>vaccined": "Vaccine",
      "listadoVacunaciones>header>mail": "E-mail",
      "listadoVacunaciones>title>notvaccinated": "Not vaccinated",
      "listadoVacunaciones>title>vaccinated": "Vaccinated",
      
      "abms>title>users": "Users",
      "abms>title>build": "Buildings",
      "abms>title>vaccines": "Vaccines",
      "abms>title>management": "Management",
      "abms>title>types": "Types of user",
      "abms>title>activateuser": "Activate user",
      "abms>title>desactivateuser": "Desactivate user",
      "abms>title>setpassword": "Set password",
      "abms>title>changemanagement": "Change management",
      
      "usuarios>title>users": "Users",
      "usuarios>title>newUsers": "New user",
      "usuarios>header>dni": "DNI.",
      "usuarios>header>name": "Name",
      
      "edificios>title>building": "Buildings",
      "edificios>button>newBuilding": "New building",
      "edificios>header>id": "ID.",
      "edificios>header>usersName": "Name",
      "edificios>header>Direction": "Direction",
      "edificios>header>parking": "With parking",
      "edificios>header>quotaParking": "Quota parking",
      
      "vacunas>title>vaccines": "Vaccines",
      "vacunas>title>vaccine": "vaccine",
      "vacunas>headers>id": "ID.",
      "vacunas>headers>name": "Name",
      
      "gerencia>title>management": "Management",
      "gerencia>label>management": "Name Management.",
      "gerencia>placeholder>name": "Enter a name ...",
      "gerencia>option>company": "Select company ...",
      "gerencia>button>changes": "Save Changes",
      "gerencia>button>confirm": "Confirm",
      "gerencia>title>delete": "Are you sure you want to delete?",
      "gerencia>text>reverse": "You will not be able to reverse the changes",
      "gerencia>button>yes": "Yes",
      "gerencia>button>newManagement": "New management",
      "gerencia>header>id": "ID.",
      "gerencia>header>name": "Name",
      "gerencia>header>ideempresa": "IDEMPRESA.",
      
      "tiposDeUsuario>title>typeUser": "Types of user",
      "tiposDeUsuario>label>description": "Description",
      "tiposDeUsuario>placeholder>enterDescription": "Enter a description ...",
      "tiposDeUsuario>button>confirm": "Confirm",
      "tiposDeUsuario>button>changes": "Save Changes",
      "tiposDeUsuario>button>buttonConfirm": "Confirm",
      "tiposDeUsuario>title>delete": "Are you sure you want to delete?",
      "tiposDeUsuario>text>reverse": "You will not be able to reverse the changes",
      "tiposDeUsuario>button>yes": "Yes",
      "tiposDeUsuario>title>typesUser": "Types of user",
      "tiposDeUsuario>button>kind": "New kind",
      "tiposDeUsuario>header>description": "Description",
      "tiposDeUsuario>title>newType": "Create new type of user",
      
      "paises>title>countries": "Countries",
      "paises>label>nameCountry": "Name country",
      "paises>placeholder>enterName": "Enter a name ...",
      "paises>button>confirm": "Confirm",
      "paises>header>name": "Name",
      "paises>title>create": "Create new country",

      "button>text>back": "Go back"
  }
};

export default en;
