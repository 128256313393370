import Header from '../shared/Header';
import Sidebar from './Sidebar2';
import React, { useState } from "react";
import QrReader from 'react-qr-reader'
import swal2 from "sweetalert2";
import * as TurnosAPI from "../apis/TurnosAPI";
import * as TurnosCocherasAPI from "../apis/TurnosCocherasAPI";
import { BtnBack } from '../components';
import { MySpinner } from '../components/MySpinner';
import * as Sentry from "@sentry/react";

const LectorQROficina = () => {
  const [facing, setFacing] = useState("environment");
  // const [url] = useState("https://www.myinstants.com/media/sounds/erro.mp3");
  // const [urlOK] = useState("https://www.myinstants.com/media/sounds/26f8b9_sonic_ring_sound_effect.mp3");

  // const [audio] = useState(new Audio(url));
  // const [playing, setPlaying] = useState(false);

  // const [audioOK] = useState(new Audio(urlOK));
  // const [playingOK, setPlayingOK] = useState(false);

  const [scanning, setScanning] = useState(false)
  const [permiso, setPermiso] = useState(false)
  const [horaPermiso, setHoraPermiso] = useState()
  const [codigo, setCodigoPermiso] = useState()
  const [tipoReserva, setTipoReserva] = useState('')

  // const toggle = useCallback((esError) => {
  //   if (esError)
  //     setPlaying(!playing);
  //   else
  //     setPlayingOK(!playingOK);
  // }, [playing, playingOK])

  // useEffect(() => {
  //   playingOK ? audioOK.play() : audioOK.play();
  // },
  //   [playingOK, audioOK]
  // );

  // useEffect(() => {
  //   playing ? audio.play() : audio.play();
  // },
  //   [playing, audio]
  // );

  // useEffect(() => {
  //   audio.addEventListener('ended', () => setPlaying(false));
  //   audioOK.addEventListener('ended', () => setPlayingOK(false));
  //   return () => {
  //     audio.removeEventListener('ended', () => setPlaying(false));
  //     audioOK.removeEventListener('ended', () => setPlayingOK(false));
  //   };
  // }, [audio, audioOK]);


  const handleTurnosApi = (endpoint, payload) => {
    endpoint(payload)
      .then(response => {
        setScanning(false)
        if (response.data.status === 'Correcto') {
          // toggle(false);
          swal2
            .fire({
              title: response.data.status,
              text: response.data.msg,
              icon: "success",
              showConfirmButton: true,
            })
            .then(() => {
              let hoy = new Date()
              setHoraPermiso(hoy.toLocaleDateString() + ' ' + hoy.toLocaleTimeString())
              setCodigoPermiso(hoy.getTime())
              setPermiso(true)
            })
        } else {
          // toggle(true);
          swal2
            .fire({
              title: response.data.status,
              text: response.data.msg,
              icon: "error",
              showConfirmButton: false,
            })
            .then(() => {
              setPermiso(false)
            })
        }
      })
      .catch(function (error) {
        setScanning(false)
        // toggle(true);
        swal2
          .fire({
            title: "Error",
            text: "No se encontraron reservas.",
            icon: "error",
            showConfirmButton: false,
          }).then(() => {
            setPermiso(false)
          })
      });
  }

  const handleScan = (data => {
    try {
      if (data && (data.length > 5) && !scanning) {
        setScanning(true)
        let buff = Buffer(data, 'base64');

        let dni = buff.toString('ascii');
        buff = Buffer(dni, 'base64');
        console.log(buff)
        let decoded = buff.toString('ascii');
        let jsonData = JSON.parse(decoded)

        if (jsonData && jsonData.tipo === 'OFICINA') {
          setTipoReserva('oficina')
          handleTurnosApi(TurnosAPI.getTurnoXQrGenerico, data)
        }
        if (jsonData && jsonData.tipo === 'COCHERA') {
          setTipoReserva('estacionamiento')
          handleTurnosApi(TurnosCocherasAPI.getTurnoXQrGenerico, data)
        }
      }
    } catch (err) {
      setScanning(false)
      Sentry.captureException(err);
      Sentry.captureMessage('Error con data: ' + JSON.stringify(data))
      swal2
        .fire({
          title: "Error",
          text: "Ocurrio un error al escanear el Qr",
          icon: "error",
          showConfirmButton: false,
        }).then(() => {
          setPermiso(false)
        })
    }
  })

  const handleError = err => {
    setScanning(false)
    Sentry.captureException(err);
    swal2
      .fire({
        title: "Error",
        text: "Por favor revisa la configuración de permisos para acceder a la cámara",
        icon: "error",
        showConfirmButton: false,
      }).then(() => {
        setPermiso(false)
      })
    console.error(err)
  }

  const choiceButton = () => {
    if (facing === "environment") {
      setFacing("user");
      return
    }
    setFacing("environment");
  }

  return (
    <div>
      <div>
        <Header />
        <Sidebar />
      </div>
      {scanning ?
        <div align='center'>
          <MySpinner />
          <p>Validando reserva en {tipoReserva}... </p>
        </div>
        :
        <div className='divPerfil'>
          {permiso ?
            <div className='permiso' align='center'>
              <h2>Reserva en {tipoReserva} confirmada.</h2>
              <br />
              <h3>Hora: {horaPermiso}</h3>
              <br />
              <h4>Codigo: {codigo}</h4>
              <br />
              <BtnBack to={'/MisReservas'} title='Volver' />
            </div>
            :
            <div>
              <h1 className='ExpertaText'>Lector QR</h1>
              <QrReader
                delay={100}
                onError={handleError}
                onScan={handleScan}
                style={{ width: '100%', maxWidth: 500, border: 'solid 1px blue' }}
                facingMode={facing}
                showViewFinder={true}
              />
              <input type="button" value="Cambiar de cámara" onClick={choiceButton} />
            </div>
          }
        </div>
      }
    </div>
  );
}

export default LectorQROficina;
