import React, { useCallback, useEffect, useState } from "react";
import './Reserva.css'
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { setMinutes, getDay, addDays, formatISO } from "date-fns";
import { useFormik } from "formik";
import Container from "@material-ui/core/Container";
import { useAlert } from 'react-alert';
import swal from "sweetalert2";
import es from "date-fns/locale/es";
import pt from "date-fns/locale/pt-BR"
import { getUser } from '../../utils/auth-helper';
import {
  FormControl,
  InputLabel,
  Button,
} from "@material-ui/core";

import Header from '../../shared/Header';
import Sidebar from '../Sidebar2';
import { getPisos, getHoras, saveTurno } from '../../apis/TurnosAPI';
// import { getUserDiagnosticoValido } from '../apis/DiagnosticosAPI';
import { useGerencias } from "../../hooks/useGerencias";
import { useUsuariosDependientes } from "../../hooks/useUsuariosDependientes";
import { useEdificios } from "../../hooks/useEdificios";
// import { usePaises } from "../hooks/usePaises";
import { MySpinner } from "../../components/MySpinner";
import { useEmpresas } from "../../hooks/useEmpresas";
import { useTranslation } from "react-i18next";
import { useValidateCampAntigripal } from "../../hooks/useValidateCampAntigripal";
import { BtnBack } from "../../components";
import { CustomSelectForm } from "../../components/CustomSelectForm";
import CheckboxDias from './CheckboxDias'
import toast from 'react-hot-toast'
import { isWeekday, ValidateHorariosCampAntigripal, validateVacunacionAntigripal } from "../../utils/fechas";
import { StepsReserva } from "./components/StepsReserva";
import { PreviewReserva } from "./components/PreviewReserva";


registerLocale("es", es);
registerLocale("pt", pt);

const MAX_RESERVA_DIAS = 4

const delay = ms => new Promise(res => setTimeout(res, ms));


const Reserva = () => {
  const { t, i18n } = useTranslation();
  const [submit, setSubmit] = useState(false);
  const [selectedDates, setSelectedDates] = useState([])
  const [pisos, setPisos] = useState(null);
  const [horarios, setHorarios] = useState(null);
  const [fechaSel, setFechaSel] = useState(formatISO(new Date(), { representation: "date" }))
  const alert = useAlert();
  const [loading, setLoading] = useState(true);
  const [conEstacionamiento, setConEstacionamiento] = useState(false);
  const [edificioConEstacionamiento, setEdificioConEstacionamiento] = useState(false);
  const [IdGerencia, setGerencia] = useState('');

  const { gerencias, loadingGerencias } = useGerencias();
  const { usuarios, loadingUsuarios } = useUsuariosDependientes();
  const { edificios, loadingEdificios } = useEdificios(fechaSel, IdGerencia)
  const { empresas, loadingEmpresas } = useEmpresas();
  const [empresa, setEmpresa] = useState(1);
  const user = getUser()
  // const [permisoEstacionamiento] = usePermisosEstacionamiento(user?.Email)
  const boolCampAntigripal = useValidateCampAntigripal(user?.Email, fechaSel)

  const [step, setStep] = useState(1);

  const [reservaValues, setReservaValues] = useState({
    usuario: null,
    edificio: null,
    piso: null,
    hora: null
  })

  const [statusReservas, setStatusReservas] = useState(null);
  const [confirmed, setConfirmed] = useState(false);
  const [sendingReserva, setSendingReserva] = useState(false);


  const formik = useFormik({
    initialValues: {
      fecha: "",
      usuario: getUser().userId,
      edificio: "",
      piso: "",
      hora: "",
    },
    onSubmit: (values) => {
      if (selectedDates.length < 1) {
        alert.show("debe seleccionar una fecha")
        return;
      }
      setReservaValues(values)

      var _statusReservas = selectedDates.map(date => {
        return {
          date: formatISO(new Date(date), { representation: "date" }),
          status: '...',
          hora: values.hora,
          IdGerencia: IdGerencia,
          edificio: values.edificio,
          piso: values.piso,
          usuario: values.usuario
        }
      });
      setStatusReservas(_statusReservas)
      setStep(2)
    },
  });

  //TODO: esto es para la demostracion
  const Simulate = async () => {
    setSendingReserva(true)
    await delay(3000);
    setConfirmed(true)
    setSendingReserva(false)
    await delay(3000);
  };




  useEffect(() => {
    //isDiagActive();
    let usuario = getUser();
    if (usuario?.IdGerencia) {
      setGerencia(usuario?.IdGerencia)
      // setGerenciaEdit(true)
    }
    setLoading(false)
  }, []);

  const resetValues = useCallback(() => {
    formik.values.edificio = "";
    formik.values.usuario = getUser().userId;
    formik.values.piso = "";
    formik.values.hora = "";
    setSubmit(false);
    setConEstacionamiento(false);
  }, [setSubmit, setConEstacionamiento, formik]);

  const setInfoEdificio = useCallback((value) => {
    let _edificio = edificios.find(e => e.eID === value)
    if (_edificio) {
      setEdificioConEstacionamiento(_edificio.ConEstacionamiento === 1 ? true : false)
      // setCupoE(_edificio.Cupo);
    }
  }, [edificios])

  const setCupoPiso = useCallback((value) => {
    let piso = pisos.find(p => p.pID === value)
    if (piso) {
      // setCupoP(piso.Cupo)
    }
  }, [pisos])

  const setCupoHorario = useCallback((value) => {
    let hr = horarios.find(h => h.id === value)
    if (hr) {
      // setCupoH(hr.Cupo);
    }
  }, [horarios])

  const handleEdificiosChange = useCallback(async (idEdificio) => {
    if (idEdificio) {
      const [pisos, horas] = await Promise.all([
        getPisos(idEdificio, fechaSel, IdGerencia),
        getHoras(idEdificio, fechaSel)
      ])
      setPisos(pisos.data);
      setHorarios(horas.data);
      setInfoEdificio(idEdificio);
      setConEstacionamiento(false)
    }
  }, [IdGerencia, fechaSel, setInfoEdificio])

  const handleGerenciasChange = useCallback((idGerencia) => {
    if (idGerencia) {
      setGerencia(idGerencia);
      resetValues()
    }
  }, [setGerencia, resetValues])

  const handleEmpresaChange = useCallback((idEmpresa) => {
    if (idEmpresa) {
      setEmpresa(idEmpresa);
      resetValues()
    }
  }, [setEmpresa, resetValues])

  const handleSubmit = () => {

    setSubmit(true);
  }


  const getEdificiosCampAntigripal = (edificios, fecha) => {
    if (fecha == '2022-05-12') {
      return edificios.filter(e => e.eID != 26)
    } else {
      return edificios.filter(e => e.eID != 30)
    }
  }

  const onDateChange = useCallback((date) => {
    let algunaFechaEliminada = false
    if (selectedDates[0] !== null) {
      selectedDates.forEach((selectedDate, index) => {

        if (date.getDate() === selectedDate.getDate()
          && date.getMonth() === selectedDate.getMonth()) {
          selectedDates.splice(index, 1)
          setSelectedDates([...selectedDates])
          algunaFechaEliminada = true
          toast.error("Fecha eliminada de la reservas");
          return
        }
      })
    }
    if (!algunaFechaEliminada) {
      if (selectedDates.length === MAX_RESERVA_DIAS) {
        toast.error(`Usted puede reservar un máximo de ${MAX_RESERVA_DIAS} días.`)
        return console.log(selectedDates);

      }
      setSelectedDates((selectedDates) => [...selectedDates, date])
    }
    console.log(selectedDates);
  }, [selectedDates])



  const selectSameDays = (date, isSelected) => {
    console.log('entro');
    if (isSelected) {
      console.log('agregar ' + date);

      for (let index = 0; index <= 30; index++) {
        if (getDay(addDays(new Date(), index)) === date) {
          if (selectedDates.length === MAX_RESERVA_DIAS) {
            toast.error(`Usted puede reservar un máximo de ${MAX_RESERVA_DIAS} días.`)
            break;
          }
          selectedDates.push(setMinutes(addDays(new Date(), index), 30))
        }
      }
      setSelectedDates([...selectedDates])

    } else {
      for (let index = 0; index <= selectedDates.length; index++) {
        console.log(index + ' ' + getDay(selectedDates[index]));
        if (getDay(selectedDates[index]) === date) {
          selectedDates.splice(index, 1)
          index--
        }
      }
      setSelectedDates([...selectedDates])
    }
    return;
  }



  const UpdateHoraReserva = (index, hora) => {
    const updated = statusReservas.map((item, i) => {
      return i === index ? {
        ...item,
        hora: hora
      } : item
    })
    setStatusReservas(updated);
  }

  return (

    <div>
      <Header />
      <Sidebar />
      {loading ||
        loadingGerencias ||
        loadingUsuarios ||
        loadingEmpresas ? (
        <MySpinner />
      ) : (
        <div>
          <br />
          <Container maxWidth="md" >
            <h1 className='ExpertaText'>{t("reserva>title>turn")}</h1>
            <p >{t("reserva>paragraph>reserve")}</p>


            {step === 1 &&
              <form onSubmit={
                formik.handleSubmit
              }
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}>

                <FormControl style={{ alignSelf: "center" }}>
                  <InputLabel>{t("reserva>titlelabel>choseDate")}</InputLabel>
                  <br />
                  <BtnBack to={'/MisReservas'} />
                  <br />
                  <div id="datePicker">
                    <DatePicker
                      wrapperClassName="react-datepicker estilos-checkbox-dias"
                      style={{ display: 'flex', width: '242px', borderBottom: 'solid 0px transparent' }}
                      locale={i18n.language}
                      name="fecha"
                      placeholderText={t("reserva>titlelabel>choseDate")}
                      dateFormat="MMMM d, yyyy"
                      showDisabledMonthNavigation
                      onChange={(date) => onDateChange(date)}
                      inline
                      selected={formik.values.fecha}
                      filterDate={isWeekday}
                      minDate={setMinutes(addDays(new Date(), 0), 30)}
                      maxDate={setMinutes(addDays(new Date(), 30), 30)}
                      highlightDates={[...selectedDates]}
                    >
                      <CheckboxDias selectSameDays={selectSameDays} />
                    </DatePicker>
                  </div>

                </FormControl>

                {empresas && <CustomSelectForm
                  title={t("reserva>titlelabel>business")}
                  value={empresa}
                  handleChange={(e) => { handleEmpresaChange(e.target.value) }}
                  options={empresas}
                  customKey='Id'
                  fieldValue='Id'
                  fieldTextValue='Nombre'
                  name="empresa"
                />}

                {gerencias && <CustomSelectForm
                  title={t("reserva>titlelabel>managfement")}
                  value={IdGerencia}
                  handleChange={(e) => { handleGerenciasChange(e.target.value) }}
                  options={gerencias.filter(ger => ger.idEmpresa === empresa)}
                  customKey='Id'
                  fieldValue='Id'
                  fieldTextValue='Nombre'
                  name="Gerencia"
                />
                }

                {!loadingEdificios ?
                  <>
                    {edificios && <CustomSelectForm
                      title={t("reserva>titlelabel>site")}
                      value={formik.values.edificio}
                      handleChange={(e) => {
                        formik.handleChange(e);
                        handleEdificiosChange(e.target.value);
                      }}
                      //options={boolCampAntigripal ? edificios : edificios.filter(e => e.eID!=26)}
                      options={boolCampAntigripal ? getEdificiosCampAntigripal(edificios, fechaSel) : edificios.filter(e => e.eID != 26 && e.eID != 30)}
                      customKey='eID'
                      fieldValue='eID'
                      fieldTextValue='Nombre'
                      fieldTextValue2='Direccion'
                      name="edificio"
                    />}

                    {usuarios && <CustomSelectForm
                      title={t("reserva>titlelabel>collaborate")}
                      value={formik.values.usuario}
                      handleChange={(e) => formik.handleChange(e)}
                      options={usuarios}
                      customKey='IdUsuario'
                      fieldValue='IdUsuario'
                      fieldTextValue='nombre'
                      name="usuario"
                    />}

                    {pisos && <CustomSelectForm
                      title={t("reserva>titlelabel>floor")}
                      value={formik.values.piso}
                      handleChange={(e) => {
                        formik.handleChange(e);
                        setCupoPiso(e.target.value);
                      }}
                      options={pisos}
                      customKey='pID'
                      fieldValue='pID'
                      fieldTextValue='Nombre'
                      name="piso"
                    />}

                    {horarios && <CustomSelectForm
                      title={t("reserva>titlelabel>schedule")}
                      value={formik.values.hora}
                      handleChange={(e) => {
                        formik.handleChange(e);
                        setCupoHorario(e.target.value);
                        handleSubmit() // activa el boton submit
                      }}
                      options={boolCampAntigripal ? horarios.filter(h => !ValidateHorariosCampAntigripal(formik.values.edificio, h.id, fechaSel)) : horarios}
                      customKey='id'
                      fieldValue='id'
                      fieldTextValue='horario'
                      name="hora"
                    />}


                    <div style={{ marginTop: "3%", alignSelf: "center" }}>
                      {submit && (
                        <Button
                          style={{ alignSelf: "center", textTransform: "none", textAlign: "center", backgroundColor: "#2f4050", color: "white" }}
                          variant="contained"
                          type='submit'>{t("reserva>button>confirm")}</Button>
                      )}
                    </div>

                  </>
                  : <MySpinner />
                }
              </form>}


            {submit && step == 2 &&
              <>
                <PreviewReserva
                  horarios={horarios}
                  reservas={statusReservas}
                  confirmed={confirmed}
                  sendingReserva={sendingReserva}
                  UpdateHoraReserva={UpdateHoraReserva}
                />

                {/* PROBANDO OTROS ESTILOS */}

                {/* <GridList className={classes.gridList}>
                  <PreviewCardReservas />
                  <PreviewCardReservas />
                  <PreviewCardReservas />
                  <PreviewCardReservas />
                  <PreviewCardReservas />
                </GridList> */}



                <StepsReserva
                  setStep={setStep}
                  Simulate={Simulate}
                  confirmed={confirmed}
                />
              </>
            }

          </Container>
        </div>)}
      <br />
    </div>
  );
}
export default Reserva;