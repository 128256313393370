import React, { useCallback, useState } from "react";
import QrReader from 'react-qr-reader';
import { useHistory } from 'react-router-dom';
import swal2 from "sweetalert2";
import * as TurnosCocherasAPI from "../apis/TurnosCocherasAPI";
import { BtnBack } from '../components';
import { MySpinner } from '../components/MySpinner';
import Header from '../shared/Header';
import Sidebar from './Sidebar2';

const LectorQRUsuario = () => {
  const [facing, setFacing] = useState("environment");
  //const [url] = useState("https://www.myinstants.com/media/sounds/erro.mp3");
  //const [urlOK] = useState("https://www.myinstants.com/media/sounds/26f8b9_sonic_ring_sound_effect.mp3");

  //const [audio] = useState(new Audio(url));
  //const [playing, setPlaying] = useState(false);

  //const [audioOK] = useState(new Audio(urlOK));
  //const [playingOK, setPlayingOK] = useState(false);

  const [scanning, setScanning] = useState(false)
  const [permiso, setPermiso] = useState(false)
  const [horaPermiso, setHoraPermiso] = useState()
  const [codigo, setCodigoPermiso] = useState()
  const history = useHistory()

  // const toggle = useCallback((esError) => {
  //   if (esError)
  //     setPlaying(!playing);
  //   else
  //     setPlayingOK(!playingOK);
  // }, [playing, playingOK])






  const handleScan = useCallback(data => {
    if (data && !scanning) {
      setScanning(true)
      console.log(data)

      TurnosCocherasAPI.getTurnoXQr(data)
        .then(response => {
          setScanning(false)
          console.log(response)

          if (response.data.status === 'Correcto') {
            swal2
              .fire({
                title: response.data.status,
                text: response.data.msg,
                icon: "success",
                showConfirmButton: true,
                // animation: true,
                // timer: 4000
              })
              .then(() => {
                let hoy = new Date()
                setHoraPermiso(hoy.toLocaleDateString() + ' ' + hoy.toLocaleTimeString())
                setCodigoPermiso(hoy.getTime())
                setPermiso(true)
              })
          }
          else {
            swal2
              .fire({
                title: response.data.status,
                text: response.data.msg,
                icon: "error",
                showConfirmButton: false,
                animation: true,
                timer: 4000
              })
              .then(() => {
                setPermiso(false)
              })
          }
        })
        .catch(function (error) {
          setScanning(false)
          swal2
            .fire({
              title: "Error",
              text: "No se encontraron reservas.",
              icon: "error",
              showConfirmButton: false,
              animation: true,
              timer: 4000
            }).then(() => {
              setPermiso(false)
            })
        });
    }
  }, [scanning, setScanning])

  const handleError = err => {
    setScanning(false)
    swal2
    .fire({
      title: "Error",
      text: "Por favor revisa la configuración de permisos para acceder a la cámara",
      icon: "error",
      showConfirmButton: false,
    }).then(() => {
      setPermiso(false)
    })
    console.error(err)
  }

  const choiceButton = () => {
    if (facing === "environment") {
      setFacing("user");
    } else {
      setFacing("environment");
    }
  }

  return (
    <div>
      <div>
        <Header />
        <Sidebar />
      </div>
      {scanning ? <MySpinner /> :
        <div className='divPerfil'>
          {permiso ?
            <div className='permiso' align='center'>
              <h2>Reserva en estacionamiento confirmada.</h2>
              <br />
              <h3>Hora: {horaPermiso}</h3>
              <br />
              <h4>Codigo: {codigo}</h4>
              <br />
              <BtnBack to={'/MisReservas'} title='Volver' />
              {/* <Button 
              onClick={()=>history.push('/MisReservas')} >Volver</Button> */}
            </div>
            :
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', minWidth: '400px'}}>
              {/* <h1 className='ExpertaText'>QR</h1> */}
              <QrReader
                delay={300}
                onError={handleError}
                onScan={handleScan}
                style={{ width: '100%', maxWidth: 500 }}
                facingMode={facing}
              />
              <input type="button" value="Cambiar de cámara" onClick={choiceButton} />
            </div>
          }
        </div>}
    </div>
  );
}

export default LectorQRUsuario;