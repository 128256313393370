import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import {
  Button,
  Modal
} from '@material-ui/core';
import { getAllEdificios } from "../../apis/EdificiosAPI";
import { MyTable } from "../../components/MyTable";
import { parseAllEdificios } from '../../utils/formatHelper'
import { useAbmStyles } from "./styles";
import { DefaultLayout } from "./container/DefaultLayout";
import { usePermisosUsuario } from "../../hooks/usePermisosUsuario";
import { useTranslation } from "react-i18next";
import EditPisosIcon from '@material-ui/icons/Settings'
import NuevoEdificio from './NuevoEdificio';

const Edificios = (props) => {
  const { t } = useTranslation();
  usePermisosUsuario([4])
  const [loading, setLoading] = useState(true)
  const classes = useAbmStyles();
  const [edificios, setEdificios] = useState([])
  let history = useHistory();
  const [modalOpen, setModalOpen] = useState(false)
  const [editar, setEditar] = useState(false)
  const [selectedEdificio, setSelectedEdificio] = useState()

  const cargarEdificios = useCallback(() => {
    setLoading(true);
    getAllEdificios()
      .then(res => {
        if (res?.data) {
          const formatAllEdificios = parseAllEdificios(res?.data) || []
          setEdificios(formatAllEdificios);
        }
        setLoading(false);
      })
      .catch(() => setLoading(false))
  }, [])


  useEffect(() => {
    cargarEdificios();
  }, [cargarEdificios]);


  const handleBtn = useCallback((u) => {
    history.push(`/EditarEdificio?id=${u.id}`);
  }, [history])

  const handleEdit = useCallback((edificio) => {
    setEditar(true);
    setSelectedEdificio(edificio)
    setModalOpen(true)
  }, [setEditar, setSelectedEdificio, setModalOpen])

  const handleNuevoEdificio = useCallback(() => {
    setModalOpen(true)
    setEditar(false)
    setSelectedEdificio()
  }, [setModalOpen, setEditar, setSelectedEdificio])

  const handleRefresh = useCallback(() => {
    setModalOpen(false)
    setEditar(false);
    setSelectedEdificio()
    cargarEdificios()
  }, [setModalOpen, setEditar, setSelectedEdificio, cargarEdificios])
  
  console.log(edificios);
  return (
    <DefaultLayout
      urlBack='/Abms'
      title={t("edificios>title>building")}
      loading={loading}
    >
      <Button
        variant="contained"
        // onClick={() => history.push('/NuevoEdificio')}
        onClick={handleNuevoEdificio}
        className={classes.btn}
      >
        {t("edificios>button>newBuilding")}</Button>

      <MyTable
        data={edificios}
        headers={[`${t("edificios>header>id")}`, `${t("edificios>header>usersName")}`, `${t("edificios>header>Direction")}`, `${t("edificios>header>parking")}`, `${t("edificios>header>quotaParking")}`]}
        fields={['id', 'nombre', 'direccion', 'conEstacionamiento', 'cupoEstacionamiento']}
        customKey={'id'}
        edit
        handleEdit={handleEdit}
        customBtn
        customBtnIcon={<EditPisosIcon />}
        handleCustomBtn={handleBtn}
        search
      />

      <Modal open={modalOpen}>
        <div className={classes.paper}>
          <Button className={classes.btnCancel} onClick={() => setModalOpen(false)}>x</Button>
          <div align='center' style={{ overflowY: 'scroll', height: '100%' }}>
            <NuevoEdificio
              onClose={handleRefresh}
              editar={editar}
              selectedEdificio={selectedEdificio}
            />
          </div>
        </div>
      </Modal>
    </DefaultLayout>
  )

}

export default Edificios;