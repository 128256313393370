import React, { useEffect, useState } from "react";
import { Formik, Form } from 'formik';
import swal from "sweetalert2";
import { useHistory } from "react-router";

import { MyTextInput, MySelect } from '../../components'
import { putPisoEdificio } from '../../apis/PisosAPI';
import { validationEditarPiso } from "./validations";
import { useAbmStyles } from "./styles";
import { getAllEdificios } from "../../apis/EdificiosAPI";
import { DefaultLayout } from "./container/DefaultLayout";
import '../../styles/styles.css';
import { usePermisosUsuario } from "../../hooks/usePermisosUsuario";


export const EditarPisoEdificio = (props) => {
    usePermisosUsuario([4])
    let history = useHistory()
    return (
        <DefaultLayout
            urlBack='/Pisos'
            title='Nuevo Piso Edificio'
        >
            <EditarPisoEdificioForm
                onClose={() => { history.push('/Abms/Pisos/Edificio')}}
            />
        </DefaultLayout>

    )
}

export const EditarPisoEdificioForm = ({
    idEdificio = '',
    selectedPisoEnGerencia,
    onClose = () => { }
}) => {
    const [loading, setLoading] = useState(true)
    const classes = useAbmStyles();
    const [edificios, setEdificios] = useState([])
    console.log(selectedPisoEnGerencia);
    useEffect(() => {
        setLoading(true);
        cargarEdificios();
    }, []);

    function cargarEdificios() {
        getAllEdificios()
            .then(res => {
                if (res?.data) {
                    setEdificios(res?.data);
                }
            })
        setLoading(false);
    }

    function submit(data) {
        console.log("DATA DESDE LA FUNCIÓN SUBMIT"+ JSON.stringify(data), typeof data);
        putPisoEdificio(data)
            .then(res => {
                console.log(res);
                if (res?.data?.status === 400) {
                    swal
                        .fire({
                            title: "Error",
                            text: "No se ha podido editar el "+selectedPisoEnGerencia.Nombre+" !",
                            icon: "warning",
                            confirmButtonColor: "#009bdb",
                            confirmButtonText: "OK",
                            animation: true,
                        })
                } else {
                    swal
                        .fire({
                            title: 'OK',
                            text: 'Se ha editado el '+data.Nombre+' correctamente.',
                            icon: "success",
                            showConfirmButton: false,
                            animation: true,
                            timer: 4000
                        })
                }
            })

        onClose()
        setLoading(false);
    }
    return (
        <Formik
            initialValues={{
                Nombre: selectedPisoEnGerencia.Nombre,
                Piso: selectedPisoEnGerencia.Numero,
                IdPiso: selectedPisoEnGerencia.Id,
                idEdificio: idEdificio
            }}
            onSubmit={(values) => {
                submit(values)
            }}
            validationSchema={validationEditarPiso}>

            {(formik) => (
                <Form>
                    <MyTextInput
                        label="Nombre"
                        name="Nombre"
                    />

                    <MyTextInput
                        label="Piso"
                        name="Piso"
                        type='number'
                    />

                    <MySelect disabled label="Edificio" name="idEdificio" >
                        <option value="">Seleccionar...</option>
                        {edificios && edificios.map(e => {
                            return <option key={e.id} value={e.id}>{e.nombre}</option>
                        })}
                    </MySelect>

                    <button type="submit" className={classes.btn} disabled={loading}>Submit</button>

                </Form>
            )
            }
        </Formik>

    )
}

