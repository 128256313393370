import React from "react"
import Checkbox from './Checkbox'

function CheckboxDias({selectSameDays}) {
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        gap: "17px",
        borderTop: "1px solid #CCC",
        paddingBottom: "5px",
      }}
    >
      <Checkbox onCheckboxChangeValue={selectSameDays} day={'lu'} extraStyle={{marginLeft: '15px'}}/>
      <Checkbox onCheckboxChangeValue={selectSameDays} day={'ma'}/>
      <Checkbox onCheckboxChangeValue={selectSameDays} day={'mi'}/>
      <Checkbox onCheckboxChangeValue={selectSameDays} day={'ju'}/>
      <Checkbox onCheckboxChangeValue={selectSameDays} day={'vi'}/>
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: 'center', textAlign: 'center', margin:'auto'}}>
        <span style={{margin:0}}>Reserva</span>
        <span style={{margin:0}}>por días</span>
      </div>
    </div>
  )
}

export default CheckboxDias
