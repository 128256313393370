import React, {useState, useEffect} from "react"

function Checkbox({onCheckboxChangeValue, day, extraStyle}) {
    const [isSelected, setIsSelected] = useState(true)
    const [dayInNumber, setDayInNumber] = useState(null)
    useEffect(() => {
      switch (day) {
        case 'lu':
            setDayInNumber(1)
            break;
        case 'ma':
            setDayInNumber(2)
            break;
        case 'mi':
            setDayInNumber(3)
            break;
        case 'ju':
            setDayInNumber(4)
            break;
        case 'vi':
            setDayInNumber(5)
            break;
        default:
            break;
      }
    }, [])
    const handleChange = () =>{
      setIsSelected(!isSelected)
      onCheckboxChangeValue(dayInNumber, isSelected)
    }
    
  return (
    <div style={{ display: "flex", flexDirection: "column", ...extraStyle}}>
      <label htmlFor="">{day}</label>
      <input type="checkbox" name="" id="" onChange={handleChange} value={isSelected}/>
    </div>
  )
}

export default Checkbox
