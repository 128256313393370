import React, { memo, useState, useEffect } from "react"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"
import { StyledButton } from "../styled-components/StyledButton"
import { Button } from "@material-ui/core"
import { style } from '../styles/StylesObject'
import { PropTypes } from "prop-types"
import { useTranslation } from "react-i18next"
export const BtnBack = memo(({ to = "/", title , ...props}) => {
  const { t } = useTranslation();
  
  return (
    <StyledButton {...props}  to={to}>
      <Button
        style={style.buttonBack}
        startIcon={<ArrowBackIcon />}
      >
        {
          title ? title
          : t('button>text>back')
        }
      </Button>
    </StyledButton>
  )
})
BtnBack.propTypes = {
  to: PropTypes.string,
  title: PropTypes.string,
  maxWidth: PropTypes.string,
  bgcolor: PropTypes.string,
}
// export const BtnBack = memo(({ to = "/", title = "Atrás" }) => {
//   return (
//     <Link
//       style={{
//         width: "100%",
//         maxWidth: "250px",
//         margin: '10px 0px',
//       }}
//       to={to}
//     >
//       <StyledButton startIcon={<ArrowBackIcon />}>{title}</StyledButton>
//     </Link>
//   )
// })

// style={{
//     position: "relative",
//     width: "100%",
//     maxWidth: "250px",
//     margin: "20px 0",
//     fontStyle: "none",
//     textDecoration: "none",
//     }}
