import React, { useState, useEffect, useCallback } from 'react'
import { useAllEdificios } from '../../hooks/useAllEdificios'
import DefaultLayout from '../abms/container/DefaultLayout'
import DatePicker, { registerLocale } from "react-datepicker";
import { formatISO } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import { FormControl, InputLabel, Button } from '@material-ui/core';
import es from "date-fns/locale/es";
import pt from "date-fns/locale/pt-BR"
import { BtnBack } from '../../components';
import { useTranslation } from 'react-i18next';
import * as TurnosAPI from "../../apis/TurnosAPI";
import { useEmpresas } from '../../hooks/useEmpresas';
import { CustomSelectForm } from '../../components/CustomSelectForm';
import { useGerencias } from '../../hooks/useGerencias';
import { useFormik } from 'formik';

registerLocale("es", es);
 registerLocale("pt", pt);

const TurnosPorDia = () => {
  const { t, i18n} = useTranslation();
  const { empresas } = useEmpresas();
  const { gerencias } = useGerencias();
  const { edificios } = useAllEdificios()
  const [pisos, setPisos] = useState([]);
  const [horarios, setHorarios] = useState([]);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const onDateChange = useCallback((dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  }, [])

  function downloadFile(absoluteUrl) {
    var link = document.createElement('a');
    link.href = absoluteUrl;
    link.download = 'Rep-' + 'turnos' + '.CSV';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const formik = useFormik({
    initialValues: {
      edificio: "TODOS",
      piso: "TODOS",
      horario: "TODOS",
      empresa: "TODOS",
      gerencia: "TODOS"
    },
    onSubmit: (values) => {
      const { edificio, piso, horario, empresa, gerencia } = values;

      const desde = formatISO(new Date(`${startDate}`), { representation: "date" });
      const hasta = formatISO(new Date(`${endDate}`), { representation: "date" });

      TurnosAPI.getReporteCompleto({
        edificio, piso, horario, empresa, gerencia, desde, hasta
      })
        .then(resp => {
          downloadFile("data:application/octet-stream," + resp.data);
        })
        .catch(err => console.error(err))
    },
  });


  return (
    <DefaultLayout title={
      //t("listadoDeTurnos>title>list")
      "Turnos por dia"
    }>
      <form style={{ width: '100%', paddingBottom: 100 }} onSubmit={formik.handleSubmit}>
        <FormControl style={{ alignSelf: "center" }}>
          <InputLabel>{t("listadoDeTurnos>label>date")}</InputLabel>
          <br />
          <BtnBack to={'/Reporte'} title={'Reportes'} />
          <br />
          <div id="datePicker">
            <DatePicker
              style={{
                marginTop: "10%",
                marginRight: "10%",
                width: "100%",
                alignSelf: "center",
                justifyContent: "center",
              }}
              locale={i18n.language}
              name="fecha"
              placeholderText={t("listadoDeTurnos>label>date")}
              dateFormat="MMMM d, yyyy"
              showDisabledMonthNavigation
              onChange={onDateChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              inline
            />
          </div>
        </FormControl>

        {empresas && <CustomSelectForm
          title={t("reserva>titlelabel>business")}
          value={formik.values.empresa}
          handleChange={(e) => {
            formik.handleChange(e);
          }}
          options={[...empresas, { Id: 'TODOS', Nombre: 'TODOS' }]}
          customKey='Id'
          fieldValue='Id'
          fieldTextValue='Nombre'
          name="empresa"
        />}

        {gerencias && <CustomSelectForm
          title={t("reserva>titlelabel>managfement")}
          value={formik.values.gerencia}
          handleChange={(e) => {
            formik.handleChange(e);
          }}
          options={
            formik.values.empresa === 'TODOS' ? [...gerencias, { Id: 'TODOS', Nombre: 'TODOS' }] :
              [...gerencias.filter(ger => ger.idEmpresa === formik.values.empresa), { Id: 'TODOS', Nombre: 'TODOS' }]
          }
          customKey='Id'
          fieldValue='Id'
          fieldTextValue='Nombre'
          name="gerencia"
          traduccion
        />
        }

        {edificios && <CustomSelectForm
          title={t("reserva>titlelabel>site")}
          value={formik.values.edificio}
          handleChange={(e) => {
            formik.handleChange(e);
          }}
          options={[...edificios, { id: 'TODOS', nombre: 'TODOS', direccion: '' }]}
          customKey='id'
          fieldValue='id'
          fieldTextValue='nombre'
          fieldTextValue2='direccion'
          name="edificio"
        />}

        {pisos && <CustomSelectForm
          title={t("reserva>titlelabel>floor")}
          value={formik.values.piso}
          handleChange={(e) => {
            formik.handleChange(e);
          }}
          options={[...pisos, { pID: 'TODOS', Nombre: 'TODOS' }]}
          customKey='pID'
          fieldValue='pID'
          fieldTextValue='Nombre'
          name="piso"
        />}

        {horarios && <CustomSelectForm
          title={t("reserva>titlelabel>schedule")}
          value={formik.values.horario}
          handleChange={(e) => {
            formik.handleChange(e);
          }}
          options={[...horarios, { id: 'TODOS', horario: 'TODOS' }]}
          customKey='id'
          fieldValue='id'
          fieldTextValue='horario'
          name="horario"
        />}

        <div style={{ marginTop: "3%", alignSelf: "center" }}>
          <Button
            style={{ alignSelf: "center", textTransform: "none", textAlign: "center", backgroundColor: "#2f4050", color: "white" }}
            variant="contained"
            type='submit'>{t("reserva>button>confirm")}</Button>
        </div>

      </form>

    </DefaultLayout>
  )
}

export default TurnosPorDia;