import { http } from "./api";

const endpointBase = 'api/turnos/';

export const getTurnos = async (values) => {
    const url = endpointBase + 'misturnos'
    return  http.get(url);
}

export const getTurno = async (idTurno) => {
    const url = endpointBase + idTurno
    return  http.get(url);
}

export const getTurnoXQr = async (idQr) => {
    const url = endpointBase + 'qr/' + idQr
    return  http.get(url);
}

export const getTurnoXQrGenerico = async (qr) => {
    const url = endpointBase + 'qr-generico/' + qr
    return  http.get(url);
}


export const getEdificios = async (fecha, IdGerencia) => {
    const url = endpointBase + 'edificios/fecha/' + fecha + '/gerencia/' + IdGerencia;
    return  http.get(url);
}


export const getHoras = async (idEdificio, fecha) => {
    const url = endpointBase + 'edificio/' + idEdificio + '/fecha/' + fecha + '/HorariosDeEntrada'
    return  http.get(url);
}

export const getPisos = async (idEdificio, fecha, idGerencia) => {
    const url = endpointBase + 'pisos/fecha/' + fecha + '/edificio/' + idEdificio + '/gerencia/' + idGerencia;
    return  http.get(url);
}

export const getTurnosHistoricos = async (values) => {
    const url = endpointBase + 'misturnoshistorico'
    return  http.get(url);
}

export const deleteTurno = async (idTurno) => {
    const url = endpointBase + idTurno
    return  http.delete(url);
}

export const saveTurno = async (idUsuario, FechaTurno, IdPiso, IdEdificio, idHorarioEntrada, conEstacionamiento, IdGerencia) => {
    const url = endpointBase
    var data = {
        "idUsuario": idUsuario,
        "fechaTurno": FechaTurno,
        "idHorarioEntrada": idHorarioEntrada,
        "IdPiso": IdPiso,
        "IdEdificio": IdEdificio,
        "ConEstacionamiento": conEstacionamiento,
        "IdGerencia": IdGerencia
    }

    return  http.post(url, data);
}
export const saveTurnosPorDia = async (idUsuario, SelectedDates, IdPiso, IdEdificio, idHorarioEntrada, conEstacionamiento, IdGerencia) =>{
    const url = endpointBase + 'reservas'
    let data = {
        "idUsuario": idUsuario,
        "fechaTurno": SelectedDates,
        "idHorarioEntrada": idHorarioEntrada,
        "IdPiso": IdPiso,
        "IdEdificio": IdEdificio,
        "ConEstacionamiento": conEstacionamiento,
        "IdGerencia": IdGerencia
    }
    return http.post(url,data)
}

export const getTurnosByEdificio = (data) => {
    const url = endpointBase + 'edificiobyfecha'
    return  http.post(url, data);
}

export const getReporteCompleto = (data) => {
    const url = endpointBase + 'reporte'
    return  http.post(url, data);
}

export const getListadoTurnos = (data) => {
    const url = endpointBase + 'listado'
    return  http.post(url, data);
}
export const getMaxCantidadDias = (data) => {
    const url = endpointBase + 'maxcantdias'
    return  http.post(url);
}
