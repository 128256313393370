import { getDay } from "date-fns";


export const validateVacunacionAntigripal = (edificio, fecha) => {
  console.log(edificio, fecha)
  if (edificio != 26 || edificio != 30) {
    return true
  } else {
    if (fecha == '2022-05-10' || fecha == '2022-05-11' || fecha == '2022-05-16' || fecha == '2022-05-19' || fecha == '2022-05-12') {
      return true
    } else {

      return false
    }
  }
}


export const ValidateHorariosCampAntigripal = (edificio, idHorario, fecha) => {
  if ((edificio == 26 || edificio == 30) && fecha == '2022-05-10') {
    return ((idHorario >= 843 && idHorario <= 848) || (idHorario >= 801 && idHorario <= 806))
  }

  if ((edificio == 26) && fecha == '2022-05-19') {
    return ((idHorario > 787 && idHorario < 795))
  }

  return false
}


export const isWeekday = (date) => {
  const day = getDay(date);
  return day !== 0 && day !== 6;
};

export function populateFeriados(holiday) {
  const feriadoData = [];
  if (holiday) {
    for (let a = 0; a < holiday.length; a++) {
      const day = holiday[a].fecha.replace(/-/g, "/");
      feriadoData.push(new Date(day));
    }
  }
  return feriadoData;
}
