import React from 'react'
import './TextoInformacionReserva.css'

function TextoInformacionReserva({informacion, title}) {
  return (
    <p className='texto-informacion-reserva'>
    <span className='title'>{title}:</span>
    <span>{informacion ? informacion.toString().substring(0,15) : 'No especificado'}</span>
  </p>
  )
}

export default TextoInformacionReserva