import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Home from "./views/Home";
import Help from "./views/Help";
import Login from "./views/Login";
import Administracion from "./views/Administracion";
import Reporte from "./views/Reporte";
import Reserva from "./views/Reserva/Reserva";
import MisReservas from "./views/misreservas/MisReservas";
import Diagnostico from "./views/Diagnostico";
import Perfil from "./views/Perfil";
import Contrasena from "./views/Contraseña";
import Lector from "./views/LectorQR";

import Abms from "./views/abms/Abms";
import Usuarios from "./views/abms/Usuarios";
import { NuevoUsuario } from "./views/abms/NuevoUsuario";
import Edificios from "./views/abms/Edificios";
import EditarEdificio from "./views/abms/EditarEdificio";
import NuevoEdificio from "./views/abms/NuevoEdificio";

import Vacunas from "./views/abms/Vacunas";
import Gerencias from "./views/abms/Gerencias";
import TiposDeUsuarios from "./views/abms/TiposDeUsuarios";
import Paises from "./views/abms/Paises";
import Sso from "./views/Sso";
import Invitado from "./views/invitados/Invitado";
import { ListadoTurnos } from "./views/turnos/ListadoTurnos";
import { ListadoTurnosEdificio } from "./views/turnos/ListadoTurnosEdificio";
import { ListadoVacunaciones } from "./views/vacunaciones/ListadoVacunaciones";

import "./utils/App.css";
import { ListaPositivosCovid } from "./views/covid/ListaPositivosCovid";
import ReservaCochera from "./views/ReservaCochera";
import LectorQRUsuario from "./views/LectorQR_usuario";
import QRImagen from "./views/QRImagen";
import Monitoreo from "./views/Monitoreo";
import LectorQROficina from "./views/LectorQR_Oficina";
import { ActivarUsuario } from "./views/reporte/ActivarUsuario";
import { DesactivarUsuario } from "./views/reporte/DesactivarUsuario";
import { SetPassword } from "./views/reporte/SetPassword";
import { CambioGerencia } from "./views/reporte/CambioGerencia";
import TurnosPorDia from "./views/reporte/TurnosPorDia";
import { Toaster } from "react-hot-toast";

class App extends Component {
  render() {
    return (
      <>
        <Router>
          <Switch>
            <Route exact path="/" component={Login} />
            <Route exact path="/Home" component={Home} />
            <Route exact path="/Help" component={Help} />
            <Route exact path="/Reserva" component={Reserva} />
            <Route exact path="/MisReservas" component={MisReservas} />
            <Route exact path="/AutoDiagnostico" component={Diagnostico} />
            <Route exact path="/Diagnostico" component={Diagnostico} />
            <Route exact path="/Administracion" component={Administracion} />
            <Route exact path="/Reporte" component={Reporte} />
            <Route exact path="/ActivarUsuario" component={Reporte} />
            <Route exact path="/DesactivarUsuario" component={Reporte} />
            <Route exact path="/SetPassword" component={Reporte} />
            <Route exact path="/CambioGerencia" component={Reporte} />
            {/* reporteria */}
            <Route exact path="/Abms" component={Abms} />
            <Route
              exact
              path="/Abms/ActivarUsuario"
              component={ActivarUsuario}
            />
            <Route
              exact
              path="/Abms/DesactivarUsuario"
              component={DesactivarUsuario}
            />
            <Route exact path="/Abms/SetPassword" component={SetPassword} />
            <Route
              exact
              path="/Abms/CambioGerencia"
              component={CambioGerencia}
            />
            <Route exact path="/Perfil" component={Perfil} />
            <Route exact path="/Contrasena" component={Contrasena} />
            <Route exact path="/LectorQR" component={Lector} />
            <Route exact path="/LectorQRUsuario" component={LectorQRUsuario} />
            <Route exact path="/LectorQROficina" component={LectorQROficina} />
            <Route exact path="/QRImagen" component={QRImagen} />
            <Route exact path="/Abms" component={Abms} />
            <Route exact path="/Monitoreo" component={Monitoreo} />
            <Route exact path="/Usuarios" component={Usuarios} />
            <Route exact path="/NuevoUsuario" component={NuevoUsuario} />
            <Route exact path="/Edificios" component={Edificios} />
            <Route exact path="/NuevoEdificio" component={NuevoEdificio} />
            <Route exact path="/EditarEdificio" component={EditarEdificio} />

            <Route exact path="/Vacunas" component={Vacunas} />
            <Route exact path="/Gerencias" component={Gerencias} />
            <Route exact path="/TiposDeUsuario" component={TiposDeUsuarios} />
            <Route exact path="/Paises" component={Paises} />
            <Route exact path="/Sso" component={Sso} />
            <Route exact path="/Invitado" component={Invitado} />
            <Route exact path="/ListadoTurnos" component={ListadoTurnos} />
            <Route
              exact
              path="/ListadoTurnosEdificio"
              component={ListadoTurnosEdificio}
            />
            <Route
              exact
              path="/ListadoVacunaciones"
              component={ListadoVacunaciones}
            />
            <Route
              exact
              path="/ListadoPositivos"
              component={ListaPositivosCovid}
            />
            <Route exact path="/ReservaCochera" component={ReservaCochera} />
            <Route exact path="/TurnosPorDia" component={TurnosPorDia} />
            <Route exact path="*" component={Home} />
          </Switch>
        </Router>
        <Toaster />
      </>
    );
  }
}

export default App;
