import React from 'react'
// import { useCantidadDiasReserva } from "../../../hooks/useCantidadDiasReserva";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { ReservaConfirmacionItem } from './ReservaConfirmacionItem';


export function PreviewReserva({
  reservas,
  horarios,
  confirmed,
  sendingReserva,
  UpdateHoraReserva = () => { }
}) {

  return (<TableContainer
    style={{
      marginTop: 100,
      textAlign: 'center',
    }}>
    <Table aria-label="simple table" style={{ width: "100%", tableLayout: "auto", maxWidth: 800, justifyContent: 'center' }}>
      <colgroup>
        <col width="20%" />
        <col width="20%" />
        <col width="60%" />
      </colgroup>
      <TableHead>
        <TableRow>
          <TableCell align='center'>Fecha</TableCell>
          <TableCell align="center">Hora</TableCell>
          {/* <TableCell>conEstacionamiento</TableCell> */}
          <TableCell align="center">Status</TableCell>
        </TableRow>
      </TableHead>

      <TableBody >
        {reservas.map((item, i) => {
          return <ReservaConfirmacionItem
            key={i.toString()}
            horarios={horarios}
            confirmed={confirmed}
            sendingReserva={sendingReserva}
            item={item}
            i={i}
            UpdateHoraReserva={UpdateHoraReserva}
          />
        })}
      </TableBody>
    </Table>
  </TableContainer>);
}

