import { Button } from '@material-ui/core';
import React from 'react'
import { Link } from 'react-router-dom';

export function StepsReserva({
  setStep,
  Simulate,
  confirmed
}) {
  return (
    <div style={{
      marginTop: "3%",
      alignSelf: "center",
      marginBottom: 100,
      paddingTop:100
    }}>
      {!confirmed ?

        <>
          <Button
            style={{
              alignSelf: "center",
              textTransform: "none",
              textAlign: "center",
              backgroundColor: "#2f4050",
              color: "white"
            }}
            variant="contained"
            onClick={() => {
              setStep(1);
            }}
            type='button'
          >Volver</Button>

          <Button
            style={{
              alignSelf: "center",
              textTransform: "none",
              textAlign: "center",
              backgroundColor: "#2f4050",
              color: "white",
              marginLeft: 20
            }}
            variant="contained"
            onClick={() => Simulate()}
            type='button'>
            Continuar</Button>

        </>

        :
        <Link
          style={{
          textDecoration: "none"
          }}
          variant="contained"
          to={'/MisReservas'}
          type='button'
        >
          <Button
            style={{
              alignSelf: "center",
              textTransform: "none",
              textAlign: "center",
              backgroundColor: "#2f4050",
              color: "white",
              marginLeft: 20
            }}
            variant="contained"
            type='button'>
            ir a "Mis reservas"</Button>
        </Link>
      }


    </div>);
}