import React, { useState, useEffect } from 'react'
import ReactLoading from 'react-loading';
import { CustomSelectForm } from '../../../components/CustomSelectForm';
import { Button } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { saveTurno } from '../../../apis/TurnosAPI';
import { useAlert } from 'react-alert';
import { useCallback } from 'react';

export function ReservaConfirmacionItem({
    horarios,
    item,
    i,
    UpdateHoraReserva,
    confirmed,
    sendingReserva,
}) {

    const [statusItem, setStatusItem] = useState();
    const [reintentarActive, setReintentarActive] = useState(false);
    const [disabled, setDisabled] = useState(false)
    const alert = useAlert();


    const guardarReserva = useCallback(() => {
        saveTurno(
            item.usuario,
            item.date,
            item.piso,
            item.edificio,
            item.hora,
            false,
            item.IdGerencia
        )
            .then(response => {
                if (response.status === 200) {
                    setStatusItem('OK')
                    setReintentarActive(false);
                    setDisabled(true)
                }
                alert.show("Reserva grabada con exito!");
            })
            .catch(function (error) {
                setReintentarActive(true);
                if (error.response === undefined || !error.response) {
                    setStatusItem('ERROR')
                    alert.show("" + error);
                }
                else {
                    setStatusItem(error.response.data.error.toString())
                    alert.show("" + error.response.data.error);
                }
            });
    }, [item, alert])


    useEffect(() => {
        if (confirmed && !reintentarActive && statusItem !== 'OK') {
            guardarReserva()
        }

    }, [confirmed, guardarReserva, reintentarActive, statusItem])


    return (
        <TableRow style={{ height: 44 }}>
            <TableCell align="center">{item.date}</TableCell>
            <TableCell align="center">
                {horarios && <CustomSelectForm
                    value={item.hora}
                    handleChange={e => {
                        UpdateHoraReserva(i, e.target.value);
                    }}
                    options={horarios}
                    customKey='id'
                    fieldValue='id'
                    fieldTextValue='horario'
                    name="hora"
                    width={100}
                    disabled={disabled}
                />}
                <br />
                {reintentarActive
                    && <Button
                        style={{
                            alignSelf: "center",
                            textTransform: "none",
                            textAlign: "center",
                            backgroundColor: "rgb(118 121 124)",
                            color: "white",
                            margin: 10
                        }}
                        onClick={() => guardarReserva()}
                    >Reintentar</Button>
                }
            </TableCell>
            {/* <TableCell>{item.conEstacionamiento}</TableCell> */}
            <TableCell align='center'>
                {sendingReserva &&
                    <ReactLoading
                        type={"spin"}
                        color={"#000000"}
                        height={'50px'}
                        width={'50px'}
                    />}

                {confirmed && !sendingReserva &&
                    <p style={{
                        color: statusItem !== 'OK' ? 'red' : '#4caf50',
                        textAlign: 'center',
                        padding: 2
                    }}>{statusItem}</p>}



            </TableCell>
        </TableRow>);
}