import React from 'react'
import { FixedSizeList as List } from 'react-window';
function ListaTurnosActivos({turnosActivos, RowA}) {
  return (
      //TODO: mover a componente externo
    <List className="reservasActivas" style={{ fontWeight: 'bold', textAlign: "center", borderColor: 'black', borderWidth: 1 }}
      height={60 * turnosActivos.length}
      width={550}
      itemSize={35}
      itemCount={turnosActivos.length} >
      {RowA}
    </List>
  )
}

export default ListaTurnosActivos