import React from 'react'
import './LeyendaColores.css'

function LeyendaColores({color, text}) {
  return (
        <div className='contenedor-leyenda'>
            <p className='text'>{text}</p>
            <div className='indicador-color' style={{backgroundColor: color}}></div>
        </div>
  )
}

export default LeyendaColores