import Button from "@material-ui/core/Button";
import { Close, DirectionsCar, Today } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import React from "react";
import { parseDate } from "utils/formatHelper";
import TextoInformacionReserva from "../components/TextoInformacionReserva";
import "./ShowMultiplesReservasModal.css";

function ShowMultiplesReservasModal({ show, turnosMultiples, borrarTurno }) {
  const closeModal = () => {
    show(false);
  };

  const onBorrarTurno = async (turnoId) => {
    const res = await borrarTurno(turnoId);
    if (res) return;
  };
  console.log(turnosMultiples);
  return (
    <div className="contenedor-modal-fullview">
      <div className="contenedor-principal-modal">
        <div className="close-button">
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => closeModal()}
            size={"small"}
          >
            <Close color={"secondary"} />
            Close
          </Button>
        </div>
        {turnosMultiples.map((turno, index) => {
          let { fecha, horario, turnoId, tipo, piso, edificio } = turno;
          fecha = parseDate(fecha);
          return (
            <>
              <h2 key={fecha.toString() + index + "h2"}>Reserva #{turnoId}</h2>
              <div key={fecha.toString() + index} className="contenedor-turno">
                {tipo === "reservas" ? (
                  <Today color={"primary"} />
                ) : (
                  <DirectionsCar color={"primary"} />
                )}
                <div className="contenedor-texto-informacion-reservas">
                  <TextoInformacionReserva
                    title={"FECHA"}
                    informacion={fecha}
                  />
                  <TextoInformacionReserva
                    title={"HORARIO"}
                    informacion={horario}
                  />
                  <TextoInformacionReserva title={"PISO"} informacion={piso} />
                  <TextoInformacionReserva
                    title={"EDIFICIO"}
                    informacion={edificio}
                  />
                </div>
                <div>
                  {tipo === "reservas" ? (
                    <DeleteIcon
                      color={"secondary"}
                      onClick={() => onBorrarTurno(turnoId)}
                    />
                  ) : (
                    <DirectionsCar color={"primary"} />
                  )}
                </div>
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
}

export default ShowMultiplesReservasModal;
