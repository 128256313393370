import { http } from "./api";

const endpointBase = 'api/pisos/';

export const getPisosEdificio = async (data) => {
    const url = endpointBase + 'edificio'
    return  http.get(url);
}

export const postPisoEdificio = async (data) => {
    const url = endpointBase + 'edificio'
    return  http.post(url, { data } );
}

export const getPisosGerencia = async (data) => {
    const url = endpointBase
    return  http.get(url+'gerencia');
}

export const getPisosByIdEdificio = async (id) => {
    const url = endpointBase + 'edificio/'+id;
    return  http.get(url);
}
export const putPisoEdificio = async (data) => {
    const url = endpointBase + 'edificio'
    console.log("data desde PISOSAPI:" + {data});
    return  http.put(url, { data } );
}


export const deleteGerenciaPiso = async (id) => {
    const url = endpointBase + 'gerencia/'+id;
    return  http.delete(url);
}

export const deletePiso = async (id) => {
    const url = endpointBase+id;
    return  http.delete(url);
}