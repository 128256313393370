import React, { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { setMinutes, getDay, addDays, formatISO } from "date-fns";
import { useFormik } from "formik";
import Container from "@material-ui/core/Container";
import { useAlert } from 'react-alert';
import swal from "sweetalert2";
import ReactLoading from 'react-loading';
import es from "date-fns/locale/es";
import pt from "date-fns/locale/pt-BR"
import { getUser } from '../utils/auth-helper';
// import { Checkbox, FormGroup, FormControlLabel } from "@material-ui/core";
import { style } from "../styles/StylesObject";

import {
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Button,
} from "@material-ui/core";

import Header from '../shared/Header';
import Sidebar from './Sidebar2';
import { saveTurno } from '../apis/TurnosCocherasAPI';
import { getUserDiagnosticoValido } from '../apis/DiagnosticosAPI';
import { useUsuariosDependientes } from "../hooks/useUsuariosDependientes";
import { useEmpresas } from "../hooks/useEmpresas";
import { useTranslation } from "react-i18next";
import { useAllEdificios } from "../hooks/useAllEdificios";
import { BtnBack } from "../components";
import { useCupoCocheras } from "../hooks/useCupoCocheras";
import { usePermisosEstacionamiento } from "../hooks/usePermisoEstacionamiento";
import { CustomSelectForm } from "../components/CustomSelectForm";

registerLocale("es", es);
registerLocale("pt", pt);

const isWeekday = (date) => {
  const day = getDay(date);
  return day !== 0 && day !== 6;
};

function populateFeriados(holiday) {
  const feriadoData = [];
  if (holiday) {
    for (let a = 0; a < holiday.length; a++) {
      const day = holiday[a].fecha.replace(/-/g, "/");
      feriadoData.push(new Date(day));
    }
  }
  return feriadoData;
}

// TODO - Exportar a un componente CustomSelectForm

const ReservaCochera = () => {
  const user = getUser()
  const history = useHistory()
  const [permisoEstacionamiento, loadingEstacionamiento] = usePermisosEstacionamiento(user?.Email)

  if (!loadingEstacionamiento && !permisoEstacionamiento) {
    history.push('/Reserva')
  }

  const { t, i18n} = useTranslation();
  const [feriados, setFeriados] = useState([]);
  const [fechaSel, setFechaSel] = useState(formatISO(new Date(), { representation: "date" }))
  const alert = useAlert();
  const [done, setDone] = useState(false);
  const [loading, setLoading] = useState(true);
  // const [cupoE, setCupoE] = useState(0);
  const { usuarios, loadingUsuarios } = useUsuariosDependientes();
  // const { empresas, loadingEmpresas } = useEmpresas();
  // const [empresa, setEmpresa] = useState(1)
  const { edificios } = useAllEdificios();


  const formik = useFormik({
    initialValues: {
      usuario: getUser().userId,
      edificio: 1,
    },
    onSubmit: (values) => {
      const { usuario, edificio } = values;
      setLoading(true);
      guardarReserva(usuario, fechaSel, edificio);
    },
  });

  const [cupoCochera, loadingCuposCochera] = useCupoCocheras(fechaSel, formik.values.edificio)

  useEffect(() => {
    setLoading(false)
  }, []);


  // const isDiagActive = () => {

  //   getUserDiagnosticoValido().then(response => {
  //     if (!response.data) {
  //       swal
  //         .fire({
  //           title: "Advertencia",
  //           text: "Debe realizar el autodiagóstico para poder reservar un turno.",
  //           icon: "warning",
  //           confirmButtonColor: "#009bdb",
  //           confirmButtonText: "OK",
  //           animation: true,
  //         })
  //         .then((result) => {
  //           if (result.value) {
  //             window.location.replace("/AutoDiagnostico");
  //           }
  //         });
  //     }
  //   })
  //     .catch(function (error) {
  //       if (error.response == undefined)
  //         alert.show("" + error);
  //       else
  //         alert.show("" + error.response.data.error);
  //     });

  // }

  async function handleDateChange(date) {
    resetValues();
    const fechaAux = formatISO(new Date(`${date}`), {
      representation: "date",
    });
    setFechaSel(fechaAux);
  }

  // function handleEmpresaChange(idEmpresa) {
  //   if (idEmpresa) {
  //     setEmpresa(idEmpresa);
  //     resetValues()
  //   }
  // }

  const resetValues = () => {
    formik.values.edificio = "";
    formik.values.usuario = getUser().userId;
  };

  const guardarReserva = (idUsuario, FechaTurno, IdEdificio) => {
    saveTurno(idUsuario, FechaTurno, IdEdificio)
      .then(response => {
        alert.show("Reserva grabada con exito!");
        setLoading(false);
        setDone(true);
      })
      .catch(function (error) {
        if (error.response == undefined) {
          alert.show("" + error);
          setLoading(false);
        }
        else {
          alert.show("" + error.response.data.error);
          setLoading(false);
        }
      });
  };


  return (

    <div>
      <Header />
      <Sidebar />

      {loading ||
        loadingUsuarios 
        // || loadingEmpresas 
        ? (
        <Container maxWidth="sm">
          <ReactLoading type={"spin"} color={"#000000"} height={'50px'} width={'50px'} />
        </Container>
      ) : (
        <div>
          <br />
          <Container maxWidth="sm" style={{display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems:'center'}}>
            <h1 className='ExpertaText'>{t('reserva>button>soloestacionamiento')}</h1>
            <p >{t("reserva>paragraph>reserve")}</p>
            <br />
            <BtnBack style={style.btnBack} title={t("reserva>button>reservaOficinas")} to={'/Reserva'} />
            <br />

            <form onSubmit={formik.handleSubmit}
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}>


              <FormControl style={{ alignSelf: "center" }}>
                <InputLabel>{t("reserva>titlelabel>choseDate")}</InputLabel>
                <div id="datePicker">
                  <DatePicker
                    style={{
                      marginTop: "10%",
                      marginRight: "10%",
                      width: "50%",
                      alignSelf: "center",
                      justifyContent: "center",
                    }}
                    id="fecha"
                    locale={i18n.language}
                    selected={formik.values.fecha}
                    name="fecha"
                    placeholderText={t("reserva>titlelabel>choseDate")}
                    onChange={(date) => handleDateChange(date)}
                    dateFormat="MMMM d, yyyy"
                    filterDate={isWeekday}
                    minDate={setMinutes(addDays(new Date(), 0), 30)}
                    maxDate={setMinutes(addDays(new Date(), 30), 30)}
                    showDisabledMonthNavigation
                    inline={formik.values.sucursalId !== ""}
                    excludeDates={populateFeriados(feriados)}
                  />
                </div>

              </FormControl>

              {/* <CustomSelectForm
                title={t("reserva>titlelabel>business")}
                value={empresa}
                handleChange={(e) => { handleEmpresaChange(e.target.value) }}
                options={empresas}
                customKey='Id'
                fieldValue='Id'
                fieldTextValue='Nombre'
                name="empresa"
              /> */}

              <CustomSelectForm
                title={t("reserva>titlelabel>site")}
                value={formik.values.edificio}
                handleChange={(e) => {
                  formik.handleChange(e);
                  // handleCuposEstacionamiento(e.target.value)
                }}
                options={edificios.filter(e => e.conEstacionamiento === 1)}
                customKey='id'
                fieldValue='id'
                fieldTextValue='nombre'
                fieldTextValue2='direccion'
                name="edificio"
              />

              {cupoCochera != null && !loadingCuposCochera && (
                <InputLabel style={{ fontSize: "10pt", textAlign: "right", color: "rgb(63, 80, 97)" }}>
                  <b>{`Cupo: ${cupoCochera}`}</b>
                </InputLabel>
              )}

              <CustomSelectForm
                title={t("reserva>titlelabel>collaborate")}
                value={formik.values.usuario}
                handleChange={(e) => formik.handleChange(e)}
                options={usuarios}
                customKey='IdUsuario'
                fieldValue='IdUsuario'
                fieldTextValue='nombre'
                name="usuario"
              />

              <div style={{ marginTop: "3%", alignSelf: "center" }}>
                {cupoCochera > 0 && !loadingCuposCochera && <Button
                  style={{ alignSelf: "center", textTransform: "none", textAlign: "center", backgroundColor: "#2f4050", color: "white" }}
                  variant="contained"
                  type='submit'>{t("reserva>button>confirm")}</Button>
                }
              </div>
            </form>
          </Container>
        </div>)}
      <br />
      {done ? <Redirect to="/MisReservas" /> : null}
    </div>
  );
}
export default ReservaCochera;