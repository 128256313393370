const es = {
  translations:
  {

    "language>currentLanguageDesc": "Español",
    "language>switchTo": "English",
    "language>switchToIcon": "en",
    "language>currentLanguageShort": "es",

    "login>paragraph>document": "Para comenzar ingresá tu DNI y contraseña.",
    "login>label>password": "Contraseña",
    "login>button>into": "Ingresar",
    "login>title>o": "o",
    "login>title>loginVrio": "Inicio de sesión en Vrio (DIRECTV|SKY)",
    "login>button>loginGuest": "Ingresar como invitado",

    "home>title>welcome": "¡Bienvenido!",
    "home>title>pass": "Debe realizar un cambio de password antes de continuar!",
    "home>h1>br1": "Safe Desk es una herramienta que te permite reservar un puesto de trabajo para los días y el horario al que vayas a la oficina. De esta manera, aseguramos el cumplimiento de los protocolos para una estadía segura y ordenada.",
    "home>h1>br2": "A través de Safe Desk podrás reservar espacios en días y horarios específicos con antelación para ocupar un escritorio en el Edificio.",
    "home>h1>br3": "Seguí estos consejos y normas de prevención para cuidarnos entre todos.",
    "home>a>turn": "Reservar nuevo espacio",

    "navbar>button>diagnosis": "Auto Diagnostico",
    "navbar>button>turn": "Reservar espacio",
    "navbar>button>reservation": "Mis Reservas",
    "navbar>button>reports": "Reportes",
    "navbar>button>management": "Administracion",
    "navbar>button>abms": "ABMs",
    "navbar>button>singOff": "Cerrar Sesion",
    "navbar>button>listadoturnos": "reservaciones",
    "navbar>button>vacunaciones": "vacunaciones",

    "navbar>link>report": "Reportar Incidente",

    "diagnostico>title>vaccination": "Vacunación:",
    "diagnostico>label>vaccinated": "Estoy vacunado",
    "diagnostico>label>doses": "Tengo aplicadas las 2 dosis de la vacuna",
    "diagnostico>bold>vaccinate": "¿Con que vacuna?",
    "diagnostico>label>yesVaccinate": "Me quiero vacunar",
    "diagnostico>label>notVaccinate": "No me quiero vacunar",
    "diagnostico>label>infoUpdate": "La informacion esta actualizada",
    "diagnostico>title>symptoms": "Sintomas:",
    "diagnostico>label>temperature": "Tengo temperatura mayor a 37°",
    "diagnostico>label>taste": "Tengo pérdida del gusto",
    "diagnostico>label>contactCovid": "Tuve contacto cercano con COVID",
    "diagnostico>label>contactCercanoExperta": "¿Estuvo en contacto con alguna persona con diagnóstico confirmado o sospecha de COVID-19 (independientemente de la distancia o del uso o no de barbijo)?",
    "diagnostico>label>contactCercanoDirectv": "¿Has tenido contacto cercano (por ejemplo, un abrazo) o (menos de 2 metros, durante al menos 15 minutos, en un ambiente cerrado sin mascarilla) en los últimos 7 días con una persona a la que se le ha diagnosticado COVID-19 o que sigue el protocolo de COVID-19?",
    "diagnostico>label>sintomasGrupo": "¿Tiene alguno de los síntomas mencionados más abajo?",
    "diagnostico>title>existing": "Condiciones pre-existentes:",
    "diagnostico>label>pregnant": "Estoy embarazada",
    "diagnostico>label>cancer": "Tengo/tuve cáncer",
    "diagnostico>label>diabetes": "Tengo diabetes",
    "diagnostico>label>disease": "Tengo alguna enfermedad hepática",
    "diagnostico>label>lossSmell": "Tengo pérdida del olfato",
    "diagnostico>label>soreThroat": "Tengo dolor de garganta",
    "diagnostico>label>breathingDifficulty": "Tengo dificultad respiratoria",
    "diagnostico>label>notSynptom": "No tengo ningun sintoma",
    "diagnostico>button>sendDiagnostic": "Enviar AutoDiagnostico",
    "diagnostico>button>indicarVacunas": "Indicar vacunas",
    "diagnostico>text>vacunasCargadas": "Vacunas cargadas en el sistema",
    "diagnostico>text>verificarInfo": "Por favor verificar informacion.",
    "diagnostico>btn>nuevaVacuna": "Nueva vacuna",
    "diagnostico>label>temp": "Temperatura de 37,5°C",
    "diagnostico>label>tos": "Tos",
    "diagnostico>label>garganta": "Dolor de garganta",
    "diagnostico>label>nasal": "Congestión nasal o rinorrea",
    "diagnostico>label>agitacion": "Agitación o sensación de falta de aire",
    "diagnostico>label>olfato": "Pérdida de olfato",
    "diagnostico>label>gusto": "Disminución del gusto",
    "diagnostico>label>diarrea": "Diarrea o vómitos",
    "diagnostico>label>cabeza": "Dolor de cabeza",
    "diagnostico>label>musculares": "Dolores musculares",
    "diagnostico>title>editarVacuna": "Editar vacuna",
    "diagnostico>title>guardarVacuna": "Nueva vacuna",
    "diagnostico>btn>cargarImagen": "Cargar imagen",
    "diagnostico>label>selectVacuna": "Seleccionar vacuna...",

    "reserva>nombre>building": "Seleccionar Edificio",
    "reserva>horario>building": "Seleccionar Edificio",
    "reserva>title>warning": "Advertencia",
    "reserva>text>selfDiagnostic": "Debe realizar el autodiagóstico para poder reservar un espacio.",
    "reserva>title>turn": "Reserva tu espacio",
    "reserva>paragraph>reserve": "Selecciona una fecha y un sitio para reservar!",
    "reserva>titlelabel>choseDate": "Elegí la fecha",
    "reserva>titlelabel>business": "Empresa",
    "reserva>titlelabel>managfement": "Gerencia o Tipo de espacio",
    "reserva>titlelabel>site": "Elegí el sitio",
    "reserva>titlelabel>collaborate": "Elegí el colaborador",
    "reserva>titlelabel>floor": "Elegí el piso",
    "reserva>titlelabel>schedule": "Elegí el horario",
    "reserva>label>parking": "Reservar con estacionamiento",
    "reserva>button>confirm": "Confirmar",
    "reserva>button>soloestacionamiento": "Sólo estacionamiento",
    "reserva>button>reservaOficinas": "Reservar Oficinas",
    "reserva>titlelabel>permisoEstacionamiento":"No tienes permisos de estacionamiento",
    
    "misReserva>title>qrGenerated": "QR generado, recorda realizar el autodiagnostico!",
    "misReserva>delete>turnDeleted": "espacio eliminado correctamente!",
    "misReserva>text>yes": "Si",
    "misReserva>text>no": "No",
    "misReserva>text>date": "Fecha:",
    "misReserva>text>site": "Sitio:",
    "misReserva>text>schedule": "Horario:",
    "misReserva>text>sospechoso": "Se ha detectado un Diagnóstico sospechoso de covid",
    "misReserva>text>diagVencido": "Tienes el autodiagnóstico vencido",
    "misReserva>text>permission": "Sin acceso a la camara del dispositivo no puedes Escanear QR",
    "misReserva>title>reservations": "Mis Reservas",
    "misReserva>button>qrIncome": "Generar QR de ingreso",
    "misReserva>button>qrScan": "Escanear QR cochera",
    "misReserva>button>qrValidarReserva": "Escanear QR",
    "misReserva>paragraph>activeReserver": "Reservas Activas",
    "misReserva>paragraph>historialReserver": "Reservas Historicas",
    "misReserva>paragraph>permissionLeyend": "*Recordá revisar la configuración en tu dispositivo para asegurarte de que el Acceso a la cámara está activado",

    "reportes>title>report": "Reporte",
    "reportes>title>reportSelect": "Selecciona un reporte",
    "reportes>name>everyone": "Todos",
    "reportes>name>report": "Seleccionar Reporte",
    "reportes>name>building": "Seleccionar Edificio",
    "reportes>hours>building": "Seleccionar Edificio",
    "reportes>value>management": "gerencia",
    "reportes>value>username": "usuario",
    "reportes>value>edifice": "edificio",
    "reportes>value>floor": "piso",
    "reportes>value>schedule": "horario",
    "reportes>value>date": "fecha",
    "reportes>label>choseReport": "Elegí el reporte",
    "reportes>label>cheseDate": "Elegí la fecha",
    "reportes>label>choseManagement": "Elegí la gerencia",
    "reportes>label>choseCollaborator": "Elegí el colaborador",
    "reportes>label>choseSite": "Elegí el sitio",
    "reportes>label>choseFloor": "Elegí el piso",
    "reportes>label>choseSchedule": "Elegí el horario",
    "reportes>button>confirm": "Confirmar",

    "administracion>title>management": "Administracion:",
    "administracion>label>eneableTurn": "Habilitar reserva de espacios",
    "administracion>label>eneableDiagnosis": "Habilitar Autodiagnostico",
    "administracion>button>saveChanges": "Guardar cambios",

    "invitados>alert>notSymptom": "No podes tener algun sintoma y elegir la opcion sin sintomas!",
    "invitados>alert>selectSymptom": "Debes seleccionar alguna opcion en sintomas!",
    "invitados>title>guests": "Invitados",
    "invitados>alert>sentDiagnosis": "Diagnostico enviado correctamente!",
    "invitados>label>selectCompany": "Elegir una empresa:",
    "invitados>textHelper>selectOption": "Por favor selecciona una opción",
    "invitados>placeholder>surnameName": "Apellido y nombres",
    "invitados>placeholder>email": "Email",
    "invitados>placeholder>companyCode": "Codigo edificio",
    "invitados>title>autoDiagnosis": "AutoDiagnóstico",
    "invitados>title>vaccination": "Vacunación:",
    "invitados>label>iAmVaccinated": "Estoy vacunado",
    "invitados>label>dosesVaccine": "Tengo aplicadas las 2 dosis de la vacuna",
    "invitados>label>whatVaccine": "¿Con que vacuna?",
    "invitados>label>wantVaccine": "Me quiero vacunar",
    "invitados>label>notVaccine": "No me quiero vacunar",
    "invitados>title>symptoms": "Sintomas:",
    "invitados>label>temperature": "Tengo temperatura mayor a 37°",
    "invitados>label>lossTaste": "Tengo pérdida del gusto",
    "invitados>label>contactCovid": "Tuve contacto cercano con COVID",
    "invitados>label>pregnant": "Estoy embarazada",
    "invitados>label>cancer": "Tengo/tuve cáncer",
    "invitados>label>diabetes": "Tengo diabetes",
    "invitados>label>disease": "Tengo alguna enfermedad hepática",
    "invitados>label>lossSmell": "Tengo pérdida del olfato",
    "invitados>label>soreThroat": "Tengo dolor de garganta",
    "invitados>label>breathingDifficulty": "Tengo dificultad respiratoria",
    "invitados>label>notSymptom": "No tengo ningun sintoma",
    "invitados>button>send": "Enviar",

    "listadoDeTurnos>title>list": "Listado de reservas",
    "listadoDeTurnos>label>date": "Elegí la fecha",
    "listadoDeTurnos>option>select": "Seleccionar edificio...",
    "listadoDeTurnos>header>user": "Usuario",
    "listadoDeTurnos>header>schedule": "Horario",
    "listadoDeTurnos>header>edifice": "Edificio",
    "listadoDeTurnos>header>data": "Fecha reserva",
    "listadoDeTurnos>header>scanning": "Escaneado",

    "listadoVacunaciones>header>dni": "DNI",
    "listadoVacunaciones>header>user": "Usuario",
    "listadoVacunaciones>header>vaccinated": "Vacunado",
    "listadoVacunaciones>header>dosis": "DosDosis",
    "listadoVacunaciones>header>vaccined": "Vacuna",
    "listadoVacunaciones>header>mail": "Email",
    "listadoVacunaciones>title>notvaccinated": "No vacunados",
    "listadoVacunaciones>title>vaccinated": "Vacunados",

    "abms>title>users": "Usuarios",
    "abms>title>build": "Edificios",
    "abms>title>vaccines": "Vacunas",
    "abms>title>management": "Gerencias",
    "abms>title>types": "Tipos de usuario",
    "abms>title>countries": "Paises",
    "abms>title>activateuser": "Activar usuario",
    "abms>title>desactivateuser": "Desactivar usuario",
    "abms>title>setpassword": "Blanquear password",
    "abms>title>changemanagement": "Cambio de gerencia",

    "usuarios>title>users": "Usuarios",
    "usuarios>title>newUsers": "Nuevo usuario",
    "usuarios>header>dni": "DNI",
    "usuarios>header>name": "Nombre",

    "edificios>title>building": "Edificios",
    "edificios>button>newBuilding": "Nuevo Edificio",
    "edificios>header>id": "id",
    "edificios>header>usersName": "Nombre",
    "edificios>header>Direction": "Direccion",
    "edificios>header>parking": "Con Estacionamiento",
    "edificios>header>quotaParking": "Cupo Estacionamiento",

    "vacunas>title>vaccines": "Vacunas",
    "vacunas>title>vaccine": "vacuna",
    "vacunas>headers>id": "id",
    "vacunas>headers>name": "Nombre",

    "gerencia>title>management": "Gerencia",
    "gerencia>label>management": "Nombre Gerencia",
    "gerencia>placeholder>name": "ingresar un nombre...",
    "gerencia>option>company": "Seleccionar empresa...",
    "gerencia>button>changes": "Guardar cambios",
    "gerencia>button>confirm": "Confirmar",
    "gerencia>title>delete": "¿Estás seguro que deseas eliminar?",
    "gerencia>text>reverse": "No podrás revertir los cambios",
    "gerencia>button>yes": "Si",
    "gerencia>button>newManagement": "Nueva gerencia",
    "gerencia>header>id": "Id",
    "gerencia>header>name": "Nombre",
    "gerencia>header>ideempresa": "idEmpresa",

    "tiposDeUsuario>title>typeUser": "Tipos de Usuario",
    "tiposDeUsuario>label>description": "Descripción",
    "tiposDeUsuario>placeholder>enterDescription": "ingresar una Descripcion...",
    "tiposDeUsuario>button>confirm": "Confirmar",
    "tiposDeUsuario>button>changes": "Guardar cambios",
    "tiposDeUsuario>button>buttonConfirm": "Confirmar",
    "tiposDeUsuario>title>delete": "¿Estás seguro que deseas eliminar?",
    "tiposDeUsuario>text>reverse": "No podrás revertir los cambios",
    "tiposDeUsuario>button>yes": "Si",
    "tiposDeUsuario>title>typesUser": "Tipos de Usuario",
    "tiposDeUsuario>button>kind": "Nuevo tipo",
    "tiposDeUsuario>header>description": "Descripción",
    "tiposDeUsuario>title>newType": "Crear nuevo tipo de usuario",

    "paises>title>countries": "Paises",
    "paises>label>nameCountry": "Nombre País",
    "paises>placeholder>enterName": "ingresar un nombre...",
    "paises>button>confirm": "Confirmar",
    "paises>header>name": "Nombre",
    "paises>title>create": "Crear nuevo País",
    
    "button>text>back": "Atrás"
  }
};

export default es;
