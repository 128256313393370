export const parseAllEdificios = (data) => {
   return data.map(d => {
        return {...d, conEstacionamiento: d.conEstacionamiento.data[0] ? 1 : 0}
    })
} 

export const parseDate = (date) =>{
    console.log(getDayOnString(date.getDay())); 
    console.log(getMonthOnString(date.getMonth()));
    let newDate = date.toString().substring(0,15)
    let _date = getDayOnString(date.getDay()) +'/'+ 
    console.log();
    return newDate
}

const getDayOnString = (day) =>{
    switch (day) {
        case 0:
            return 'Domingo'
            
        case 1:
            return 'Lunes'
            
        case 2:
            return 'Martes'
            
        case 3:
            return 'Miercoles'
            
        case 4:
            return 'Jueves'
            
        case 5:
            return 'Viernes'
            
        case 6:
            return 'Sabado'
        
        default:
            break;
    }
}
const getMonthOnString = (month) =>{
    switch (month) {
        case 0:
            return 'Enero'
            
        case 1:
            return 'Febrero'
            
        case 2:
            return 'Marzo'
            
        case 3:
            return 'Abril'
            
        case 4:
            return 'Mayo'
            
        case 5:
            return 'Junio'
            
        case 6:
            return 'Julio'
        case 7:
            return 'Agosto'
        case 8:
            return 'Septiembre'
        case 9:
            return 'Octubre'
        case 10:
            return 'Noviembre'
        case 11:
            return 'Diciembre'
        
        default:
            break;
    }
}
