import React from 'react'
import { FixedSizeList as List } from 'react-window';

function ListaTurnosHistoricos({turnosHistoricos,RowH}) {
  return (
    <List className="reservasHistoricas" style={{ fontWeight: 'bold', textAlign: "center" }}
    height={65 * turnosHistoricos.length}
    width={550}
    itemSize={35}
    itemCount={turnosHistoricos.length}>
    {RowH}
  </List>
  )
}

export default ListaTurnosHistoricos