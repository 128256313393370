import React, { useState, useCallback } from 'react'
import { useAllEdificios } from '../../hooks/useAllEdificios'
import DefaultLayout from '../abms/container/DefaultLayout'
import DatePicker, { registerLocale } from "react-datepicker";
import { formatISO } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import { FormControl, InputLabel, Button } from '@material-ui/core';
import es from "date-fns/locale/es";
import pt from "date-fns/locale/pt-BR"
import { BtnBack, MyTable } from '../../components';
import { useTranslation, i18 } from 'react-i18next';
import * as TurnosAPI from "../../apis/TurnosAPI";
import { CustomSelectForm } from '../../components/CustomSelectForm';
import { useFormik } from 'formik';
import ChartSemiCircle from '../../components/chart/semiCircleChart';
import ParetoChart from '../../components/chart/ParetoChart';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import ChartCircle from '../../components/chart/circleChart';
import { useEmpresas } from '../../hooks/useEmpresas';
import { useGerencias } from '../../hooks/useGerencias';

const { CountObjects } = require("count-objects");
const jsonexport = require('jsonexport');

registerLocale("es", es);
registerLocale("pt", pt);
const SwitchActive = ({ active, setActive }) => {
    return (
        <button
            style={{ padding: '0px' }}
            onClick={() => setActive(!active)}>{active ? '[ - ]' : '[ + ]'}
        </button>
    )
}


//TODO: Sacar a utils
const downloadCSV = async (data, name) => {
    const blob = new Blob([data], { type: "data:text/csv;charset=utf-8," });
    const blobURL = window.URL.createObjectURL(blob);

    // Create new tag for download file
    const anchor = document.createElement("a");
    anchor.download = name;
    anchor.href = blobURL;
    anchor.dataset.downloadurl = ["text/csv", anchor.download, anchor.href].join(":");
    anchor.click();

    // Remove URL.createObjectURL. The browser should not save the reference to the file.
    setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        URL.revokeObjectURL(blobURL);
    }, 100);
};
//TODO: Sacar a utils
const handleDownload = (data, filename) => {
    jsonexport(data, function (err, csv) {
        if (err) return console.error(err);
        downloadCSV(csv, filename)
    });
}

export const ListadoTurnos = () => {
    const { t, i18n} = useTranslation();
    const { edificios } = useAllEdificios()
    const { empresas } = useEmpresas();
    const { gerencias } = useGerencias();

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const [loadingData, setLoadingData] = useState(false)

    const [listado, setListado] = useState([]);
    const [chartEscaneados, setChartEscaneados] = useState([]);
    const [chartPorPisoData, setChartPorPisoData] = useState([]);
    const [chartPorGerenciaData, setChartPorGerenciaData] = useState([])
    const [chartPorFechaData, setChartPorFechaData] = useState([])
    const [chartEstacionamiento, setChartEstacionamiento] = useState([])
    const [chartPorEdificioData, setChartPorEdificioData] = useState([])

    const [activeListadoTurnos, setActiveListadoTurnos] = useState(true)


    const onDateChange = useCallback((dates) => {
        const [start, end] = dates;
        console.log(dates)
        setStartDate(start);
        setEndDate(end);
    }, [])

    const handleQrEscaneados = useCallback((data) => {
        const escaneados = data.filter(i => i.QrEscaneado).length
        setChartEscaneados([{
            label: 'Escaneadas',
            value: escaneados,
            color: '#77DD77'
        }, {
            label: 'Sin Escanear',
            value: data.length - escaneados
        }])
    }, [])

    const handleListadoData = async (data) => {
        const co = new CountObjects(data);
        const countObject = co.count();
        const [dataPorPiso, dataPorGerencia, dataPorFecha, dataPorEdificio] = await Promise.all([
            Object.keys(countObject.Piso).map((i) => { return { piso: i, cantidad: countObject.Piso[i] } }),
            Object.keys(countObject.Gerencia).map((i) => { return { gerencia: i, cantidad: countObject.Gerencia[i] } }),
            Object.keys(countObject.FechaTurno).map((i) => { return { fecha: i.substring(0, 10), cantidad: countObject.FechaTurno[i] } }),
            Object.keys(countObject.Edificio).map((i) => { return { edificio: i.substring(0, 10), cantidad: countObject.Edificio[i] } })
        ])

        setChartPorPisoData(dataPorPiso)
        setChartPorGerenciaData(dataPorGerencia)
        setChartPorFechaData(dataPorFecha)
        setChartEstacionamiento([{ label: 'Con cochera', value: countObject.ConEstacionamiento['1'] || 0 }, { label: 'Sin Cochera', value: countObject.ConEstacionamiento['0'] || 0 }])
        setChartPorEdificioData(dataPorEdificio)
    }

    const formik = useFormik({
        initialValues: {
            edificio: "TODOS",
            empresa: "TODOS",
            gerencia: "TODOS"
        },
        onSubmit: (values) => {
            const { edificio, empresa, gerencia } = values;

            const desde = formatISO(new Date(`${startDate}`), { representation: "date" });
            const hasta = endDate ? formatISO(new Date(`${endDate}`), { representation: "date" }) : desde

            setLoadingData(true)
            TurnosAPI.getListadoTurnos({ edificio, desde, hasta, empresa, gerencia })
                .then(resp => {
                    if (resp.data && resp.data.length > 0) {
                        setListado(resp.data)
                        handleQrEscaneados(resp.data)
                        handleListadoData(resp.data)
                    } else {
                        setListado([])
                        setChartPorPisoData([])
                        setChartPorGerenciaData([])
                        setChartPorFechaData([])
                        setChartPorEdificioData([])
                    }
                    setLoadingData(false)
                })
                .catch(err => { console.error(err); setListado([]) })
        },
    });


    return (
        <DefaultLayout title={t("listadoDeTurnos>title>list")} loading={loadingData}>
            <form style={{ width: '100%', paddingBottom: 100 }} onSubmit={formik.handleSubmit}>
                <FormControl style={{ alignSelf: "center" }}>
                    <InputLabel>{t("listadoDeTurnos>label>date")}</InputLabel>
                    <br />
                    <BtnBack to={'/Reporte'} title={'Reportes'} />
                    <br />
                    <div id="datePicker">
                        <DatePicker
                            style={{
                                marginTop: "10%",
                                marginRight: "10%",
                                width: "100%",
                                alignSelf: "center",
                                justifyContent: "center",
                            }}
                            locale={i18n.language}
                            name="fecha"
                            placeholderText={t("listadoDeTurnos>label>date")}
                            dateFormat="MMMM d, yyyy"
                            showDisabledMonthNavigation
                            onChange={onDateChange}
                            startDate={startDate}
                            endDate={endDate}
                            selectsRange
                            inline
                        />
                    </div>
                </FormControl>

                {empresas && <CustomSelectForm
                    title={t("reserva>titlelabel>business")}
                    value={formik.values.empresa}
                    handleChange={(e) => {
                        formik.handleChange(e);
                    }}
                    options={[...empresas, { Id: 'TODOS', Nombre: 'TODOS' }]}
                    customKey='Id'
                    fieldValue='Id'
                    fieldTextValue='Nombre'
                    name="empresa"
                />}

                {gerencias && <CustomSelectForm
                    title={t("reserva>titlelabel>managfement")}
                    value={formik.values.gerencia}
                    handleChange={(e) => {
                        formik.handleChange(e);
                    }}
                    options={
                        formik.values.empresa === 'TODOS' ? [...gerencias, { Id: 'TODOS', Nombre: 'TODOS' }] :
                            [...gerencias.filter(ger => ger.idEmpresa === formik.values.empresa), { Id: 'TODOS', Nombre: 'TODOS' }]
                    }
                    customKey='Id'
                    fieldValue='Id'
                    fieldTextValue='Nombre'
                    name="gerencia"
                    traduccion
                />}


                {edificios && <CustomSelectForm
                    title={t("reserva>titlelabel>site")}
                    value={formik.values.edificio}
                    handleChange={(e) => {
                        formik.handleChange(e);
                    }}
                    options={[...edificios, { id: 'TODOS', nombre: 'TODOS', direccion: '' }]}
                    customKey='id'
                    fieldValue='id'
                    fieldTextValue='nombre'
                    fieldTextValue2='direccion'
                    name="edificio"
                />}

                <div style={{ marginTop: "3%", alignSelf: "center" }}>
                    <Button
                        style={{ alignSelf: "center", textTransform: "none", textAlign: "center", backgroundColor: "#2f4050", color: "white" }}
                        variant="contained"
                        type='submit'
                        disabled={formik.values.edificio === ''}
                    >
                        { //t("reserva>button>confirm")
                            "Consultar"
                        }
                    </Button>
                </div>
            </form>

            <div style={{ maxWidth: '80vw', marginBottom: 30 }}>
                <h3>Reservaciones vs QR Scaneados </h3>
                {chartEscaneados &&
                    <ChartSemiCircle
                        name="graph_qr_escaneados"
                        data={chartEscaneados ? chartEscaneados : [] }
                        value="value"
                        category="label"
                    />}
                <SaveAltIcon onClick={() => handleDownload(chartEscaneados, 'chartEscaneados.csv')} />
            </div>

            <div style={{ maxWidth: '80vw', marginBottom: 30 }}>
                <h3>Reservas con o sin Estacionamiento </h3>
                {chartEstacionamiento &&
                    <ChartSemiCircle
                        name="graph_cochera"
                        data={chartEstacionamiento ? chartEstacionamiento : []}
                        value="value"
                        category="label"
                    />}
                <SaveAltIcon onClick={() => handleDownload(chartEstacionamiento, 'chartEstacionamiento.csv')} />
            </div>

            <div style={{ maxWidth: '80vw', marginBottom: 30 }}>
                <h3>Reservaciones por piso </h3>
                {chartPorPisoData && <ParetoChart
                    name="chartPorPiso"
                    data={chartPorPisoData}
                    value="cantidad"
                    category="piso"
                />}
                {chartPorPisoData && <ChartCircle
                    name="chartPorPiso2"
                    data={chartPorPisoData}
                    value="cantidad"
                    category="piso"
                />}
                <SaveAltIcon onClick={() => handleDownload(chartPorPisoData, 'chartPorPisoData.csv')} />
            </div>

            <div style={{ maxWidth: '80vw', marginBottom: 30 }}>
                <h3>Reservaciones por gerencia o tipo de espacio </h3>
                {chartPorGerenciaData && <ParetoChart
                    name="chartPorGerencia"
                    data={chartPorGerenciaData}
                    value="cantidad"
                    category="gerencia"
                />}
                {chartPorGerenciaData && <ChartCircle
                    name="chartPorGerencia2"
                    data={chartPorGerenciaData}
                    value="cantidad"
                    category="gerencia"
                />}
                <SaveAltIcon onClick={() => handleDownload(chartPorGerenciaData, 'chartPorGerenciaData.csv')} />
            </div>

            <div style={{ maxWidth: '80vw', marginBottom: 30 }}>
                <h3>Reservaciones por edificio </h3>
                {chartPorEdificioData && <ParetoChart
                    name="chartPorEdificio"
                    data={chartPorEdificioData}
                    value="cantidad"
                    category="edificio"
                />}
                {chartPorEdificioData && <ChartCircle
                    name="chartPorEdificio2"
                    data={chartPorEdificioData}
                    value="cantidad"
                    category="edificio"
                />}
                <SaveAltIcon onClick={() => handleDownload(chartPorEdificioData, 'chartPorEdificioData.csv')} />
            </div>

            <div style={{ maxWidth: '80vw', marginBottom: 30 }}>
                <h3>Reservaciones por fecha </h3>
                {chartPorFechaData && <ParetoChart
                    name="chartPorFecha"
                    data={chartPorFechaData}
                    value="cantidad"
                    category="fecha"
                />}
                <SaveAltIcon onClick={() => handleDownload(chartPorFechaData, 'reportePorFechas.csv')} />
            </div>

            <div style={{ margin: '10px 0 100px 0', width: '100%' }}>
                <h3>Listado de reservas <SwitchActive active={activeListadoTurnos} setActive={setActiveListadoTurnos} /></h3>
                {activeListadoTurnos &&
                    <MyTable
                        search={true}
                        data={listado}
                        headers={[`${t("listadoDeTurnos>header>user")}`, `${t("listadoDeTurnos>header>schedule")}`, `${t("listadoDeTurnos>header>edifice")}`, `${t("listadoDeTurnos>header>data")}`, `${t("listadoDeTurnos>header>scanning")}`, `${t("reserva>titlelabel>managfement")}`]}
                        fields={['Email', 'Horario', 'Edificio', 'FechaTurno', 'QrEscaneado', 'Gerencia']}
                    />}
                <SaveAltIcon onClick={() => handleDownload(listado, 'listado.csv')} />
            </div>



        </DefaultLayout>
    )
}
