import React from 'react'
import { FixedSizeList as List } from 'react-window';

function ListaTurnosCocheraActivos({turnosCocheraActivos,RowC}) {
  return (
    <List className="reservasActivas" style={{ textAlign: "center", borderColor: 'black', borderWidth: 1 }}
    height={50 * turnosCocheraActivos.length}
    width={550}
    itemSize={35}
    itemCount={turnosCocheraActivos.length} >
    {RowC}
  </List>
  )
}

export default ListaTurnosCocheraActivos