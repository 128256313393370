import React, { useState, useEffect, useCallback } from "react";
import Container from "@material-ui/core/Container";
import "react-datepicker/dist/react-datepicker.css";
import "./MisReservas.css";
import ShowMultiplesReservasModal from "./views/ShowMultiplesReservasModal";
import { Collapse } from "@material-ui/core";
import LeyendaColores from "./components/LeyendaColores";
import * as TurnosAPI from "../../apis/TurnosAPI";
import * as TurnosCocherasAPI from "../../apis/TurnosCocherasAPI";
import Header from "../../shared/Header";
import Sidebar from "../Sidebar2";
import { FixedSizeList as List } from "react-window";
import { useAlert } from "react-alert";
import TodayIcon from "@material-ui/icons/Today";
import Divider from "@material-ui/core/Divider";
import DriveEtaIcon from "@material-ui/icons/DriveEta";
import Button from "@material-ui/core/Button";
import swal from "@sweetalert/with-react";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import DeleteIcon from "@material-ui/icons/Delete";
import ArrowDropDownCircle from "@material-ui/icons/ArrowDropDownCircle";
import { getUser } from "../../utils/auth-helper";
// import { getUserDiagnosticoValido } from '../apis/DiagnosticosAPI';
import {
  // decode as base64_decode,
  encode as base64_encode,
} from "base-64";
import { MySpinner } from "../../components/MySpinner";
import { useTranslation } from "react-i18next";
import { useSospechoso } from "../../hooks/useSospechoso";
import QRCode from "qrcode.react";
import { useHistory } from "react-router-dom";
import { UseDiagnosticoValido } from "../../hooks/useDiagnosticoValido";
import * as Sentry from "@sentry/react";
import DatePicker, { registerLocale } from "react-datepicker";
import { setMinutes, getDay, addDays, formatISO } from "date-fns";
import { style } from "../../styles/StylesObject";
import ListaTurnosActivos from "./components/ListaTurnosActivos";
import ListaTurnosHistoricos from "./components/ListaTurnosHistoricos";
import ListaTurnosCocheraActivos from "./components/ListaTurnosCocheraActivos";
import { DirectionsCar, LocalParking } from "@material-ui/icons";

const getParsedDate = (date) => {
  let _date = String(date).split(" ");
  var days = String(_date[0]).split("-");
  return [
    parseInt(days[2]) + "/" + parseInt(days[1]) + "/" + parseInt(days[0]),
  ];
};

const MisReservas = (prop) => {
  const alert = useAlert();
  const { t, i18n } = useTranslation();
  const [refresh, setRefresh] = useState(false);
  const [showMultiplesReservasModal, setShowMultiplesReservasModal] =
    useState(false);
  const [showReservasHistoricas, setShowReservasHistoricas] = useState(false);
  const [reservasSimultaneasPorFecha, setReservasSimultaneasPorFecha] =
    useState([]);
  const [turnosActivos, setTurnosActivos] = useState([]);
  const [turnosActivosData, setTurnosActivosData] = useState([]);
  const [turnosCocheraActivos, setTurnosCocherasActivos] = useState([]);
  const [turnosCocheraActivosData, setTurnosCocherasActivosData] = useState([]);
  const [turnosHistoricos, setTurnosHistoricos] = useState([]);
  const [turnosHistoricosData, setTurnosHistoricosData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tienePermisosCamara, setTienePermisosCamara] = useState();
  const [loadingPermisos, setLoadingPermisos] = useState(false);
  const [selectedDates, setSelectedDates] = useState([]);
  const [auxHighLightWithRanges, setAuxHighLightWithRanges] = useState([]);
  // const [turnoActivo, setTurnoActivo] = useState(0);
  const sospechoso = useSospechoso();
  const diagnostivoActivo = UseDiagnosticoValido();
  const history = useHistory();
  let company = localStorage.getItem("company");
  const [highLightWithRanges, setHighLightWithRanges] = useState([]);

  const getTurnosHistorico = useCallback(() => {
    TurnosAPI.getTurnosHistoricos()
      .then((response) => {
        var result = [];
        for (var i in response.data)
          result.push(
            `${t("misReserva>text>date")}` +
              getParsedDate(response.data[i].FechaTurno.substr(0, 10)) +
              ` ${t("misReserva>text>site")}` +
              response.data[i].Edificio +
              " " +
              response.data[i].Piso +
              `, ${t("misReserva>text>schedule")}` +
              response.data[i].Horario
          );
        setLoading(false);
        setTurnosHistoricosData(response.data);
        setTurnosHistoricos(result.reverse());
      })
      .catch(function (error) {
        if (!error.response) alert.show("" + error);
        else alert.show("" + error.response.data.error);
      });
  }, [setTurnosHistoricos, alert, t]);

  useEffect(() => {
    let reservasCocherasParsed = [];
    let auxConTodosLosTurnosYHorarios = [];
    turnosCocheraActivosData.forEach((turno) => {
      let newDate = new Date(turno.FechaTurno);
      newDate.setDate(newDate.getDate() + 1);
      reservasCocherasParsed.push(newDate);
      auxConTodosLosTurnosYHorarios.push({
        fecha: newDate,
        horario: turno.FechaTurno,
        turnoId: turno.Id,
        edificio: turno.Edificio,
        tipo: "reservasCocheras",
      });
    });
    let reservasParsed = [];
    turnosActivosData.forEach((turno) => {
      let newDate = new Date(turno.FechaTurno);
      newDate.setDate(newDate.getDate() + 1);
      reservasParsed.push(newDate);
      auxConTodosLosTurnosYHorarios.push({
        fecha: newDate,
        horario: turno.Horario,
        turnoId: turno.TurnoId,
        edificio: turno.Edificio,
        piso: turno.Piso,
        tipo: "reservas",
      });
    });
    let reservasHistoricasParsed = [];
    turnosHistoricosData.forEach((turnoHistorico) => {
      let newDate = new Date(turnoHistorico.FechaTurno);
      newDate.setDate(newDate.getDate() + 1);
      reservasHistoricasParsed.push(newDate);
      auxConTodosLosTurnosYHorarios.push({
        fecha: newDate,
        horario: turnoHistorico.Horario,
        turnoId: turnoHistorico.TurnoId,
        edificio: turnoHistorico.Edificio,
        piso: turnoHistorico.Piso,
        tipo: "reservasHistoricas",
      });
    });
    let highlightedRanges = {
      highlightedReservasHistoricas: reservasHistoricasParsed,
    };
    let reservaHighlighted = {
      reservas: reservasParsed,
    };
    let reservaCocherasHighlighted = {
      reservasCocheras: reservasCocherasParsed,
    };
    setAuxHighLightWithRanges([...auxConTodosLosTurnosYHorarios]);
    setHighLightWithRanges([
      highlightedRanges,
      reservaHighlighted,
      reservaCocherasHighlighted,
    ]);

    // turnosActivos.forEach(() =>{
    //   let date = new Date()
    //   setSelectedDates(...turnosActivos)
    // })
  }, [turnosActivosData, turnosHistoricosData, turnosCocheraActivosData]);
  useEffect(() => {
    if (
      highLightWithRanges[1] === undefined ||
      highLightWithRanges[2] === undefined
    )
      return console.trace("NO HAY NADA CARGADO TODAVIA");

    let reservas = highLightWithRanges[1].reservas;
    let reservasCocheras = highLightWithRanges[2].reservasCocheras;
    let mayorCantidadDeReservas =
      reservas.length > reservasCocheras.length
        ? reservas.length
        : reservasCocheras.length;
    let auxReservasConMasFechas =
      reservas.length > reservasCocheras.length
        ? reservas.sort()
        : reservasCocheras.sort();
    let auxReservasConMenosFechas =
      reservas.length > reservasCocheras.length
        ? reservasCocheras.sort()
        : reservas.sort();
    let reservasConMismaFecha = {
      reservasMultiples: [],
    };
    for (let index = 0; index < mayorCantidadDeReservas; index++) {
      const reservasConMasFechas = auxReservasConMasFechas[index];
      if (reservasConMasFechas === auxReservasConMasFechas[index + 1])
        reservasConMismaFecha.reservasMultiples.push(reservasConMasFechas);
    }
    for (let index = 0; index < auxReservasConMenosFechas; index++) {
      const reservasConMenosFechas = auxReservasConMenosFechas[index];
      if (reservasConMenosFechas === auxReservasConMenosFechas[index + 1])
        reservasConMismaFecha.reservasMultiples.push(reservasConMenosFechas);
    }
    reservasCocheras.sort();
    if (reservasCocheras.length < 0 || reservas.length < 0)
      return console.trace("NO HAY RESERVAS CON DIAS IGUALES");
    for (let index = 0; index < mayorCantidadDeReservas; index++) {
      const reserva_o_cochera_con_mas_fechas = auxReservasConMasFechas[index]
        ? auxReservasConMasFechas[index]
        : " ";
      for (let index = 0; index < auxReservasConMenosFechas.length; index++) {
        const reserva_o_cochera_con_menos_fechas = auxReservasConMenosFechas[
          index
        ]
          ? auxReservasConMenosFechas[index]
          : "";
        if (
          reserva_o_cochera_con_mas_fechas.toString().substring(4, 15) ===
          reserva_o_cochera_con_menos_fechas.toString().substring(4, 15)
        ) {
          reservasConMismaFecha.reservasMultiples.push(
            reserva_o_cochera_con_mas_fechas
          );
          console.log(reservasConMismaFecha.reservasMultiples);
        }
      }
    }
    setHighLightWithRanges([...highLightWithRanges, reservasConMismaFecha]);
    console.log(highLightWithRanges);
  }, [auxHighLightWithRanges]);
  useEffect(() => {
    var constraints = { audio: true, video: true };
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((permisson) => {
        console.log("El usuario activo la camara", permisson);
        // @ts-ignore
        setTienePermisosCamara(permisson.active);
      })
      .catch((err) => console.log("No se pudo activar la camara" + err));
  }, []);

  //TODO: mover a hooks
  const getTurnosActivos = useCallback(() => {
    TurnosAPI.getTurnos()
      .then((response) => {
        var result = [];
        for (var i in response.data) {
          // if (i == 0 && response.data[i].FechaTurno.substr(0, 10) == getCurrentDate('-')) {
          //   setTurnoActivo(response.data[i].TurnoId);
          // }
          result.push(
            `${t("misReserva>text>date")}` +
              getParsedDate(response.data[i].FechaTurno.substr(0, 10)) +
              ` ${t("misReserva>text>site")}` +
              response.data[i].Edificio +
              " " +
              response.data[i].Piso +
              `, ${t("misReserva>text>schedule")}` +
              response.data[i].Horario
          );
        }
        setLoading(false);
        setTurnosActivos(result);
        setTurnosActivosData(response.data);
      })
      .catch(function (error) {
        if (!error.response) alert.show("" + error);
        else alert.show("" + error.response.data.error);
      });
  }, [alert, t]);
  //TODO: mover a hooks
  const getTurnosCocherasActivos = useCallback(() => {
    TurnosCocherasAPI.getTurnosCochera()
      .then((response) => {
        var result = [];
        for (var i in response.data) {
          // if (i == 0 && response.data[i].FechaTurno.substr(0, 10) == getCurrentDate('-')) {
          //   setTurnoActivo(response.data[i].Id);
          // }
          result.push(
            `${t("misReserva>text>date")} ` +
              getParsedDate(response.data[i].FechaTurno.substr(0, 10)) +
              ` ${t("misReserva>text>site")} ` +
              response.data[i].Edificio +
              ` ${"(cochera)"} `
          );
        }
        setLoading(false);
        setTurnosCocherasActivosData(response.data);
        setTurnosCocherasActivos(result);
      })
      .catch(function (error) {
        if (!error.response) alert.show("" + error);
        else alert.show("" + error.response.data.error);
      });
  }, [alert, t]);

  //TODO: mover a hooks
  useEffect(() => {
    setLoading(true);
    getTurnosActivos();
    getTurnosCocherasActivos();
    getTurnosHistorico();
  }, [getTurnosActivos, getTurnosCocherasActivos, getTurnosHistorico]);

  const clickQR = () => {
    if (sospechoso) {
      return swal(
        <div>
          <h1>Alerta de diagnóstico sospechoso</h1>
        </div>
      );
    }
    swal(
      <div>
        <h1>{t("misReserva>title>qrGenerated")}</h1>
        <br />
        <h3>{getUser().Nombre}</h3>
        <br />
        <p>
          <QRCode
            value={"" + base64_encode("" + base64_encode(getUser().userId))}
          />
        </p>
      </div>
    );

    /*if (turnoActivo == 0)
    {
     swal(
       <div>
         <h1>El QR no se pudo generar...</h1>
         <br></br>
         No se encontraron turnos para el dia de hoy...
       </div>
     )
   }
   else
   {
 
     getUserDiagnosticoValido().then(response => {
       if (!response.data){
           swal2
           .fire({
             title: "Advertencia",
             text: "Debe realizar el autodiagóstico para poder generar el QR.",
             icon: "warning",
             confirmButtonColor: "#009bdb",
             confirmButtonText: "OK",
             animation: true,
           })
           .then((result) => {
               window.location.replace("/Diagnostico");
           });
         }
       else
       {
         swal(
           <div>
             <h1>QR generado!</h1>
             <br/>
             <h3>{turnosActivos[0]}</h3>
             <br/>
             <p>
             <QRCode value={"" + turnoActivo} />
             </p>
           </div>
         )
       }
       })          
       .catch(function(error) {
             if (error.response == undefined)
               alert.show("" + error);
             else
               alert.show("" + error.response.data.error);
       });
 
     /*if (turnoActivo == 0)
     {
      swal(
        <div>
          <h1>El QR no se pudo generar...</h1>
          <br></br>
          No se encontraron turnos para el dia de hoy...
        </div>
      )
    }
    else
    {
  
      getUserDiagnosticoValido().then(response => {
        if (!response.data){
            swal2
            .fire({
              title: "Advertencia",
              text: "Debe realizar el autodiagóstico para poder generar el QR.",
              icon: "warning",
              confirmButtonColor: "#009bdb",
              confirmButtonText: "OK",
              animation: true,
            })
            .then((result) => {
                window.location.replace("/Diagnostico");
            });
          }
        else
        {
          swal(
            <div>
              <h1>QR generado!</h1>
              <br/>
              <h3>{turnosActivos[0]}</h3>
              <br/>
              <p>
              <QRCode value={"" + turnoActivo} />
              </p>
            </div>
          )
        }
        })          
        .catch(function(error) {
              if (error.response == undefined)
                alert.show("" + error);
              else
                alert.show("" + error.response.data.error);
        });
  
      }*/
  };

  //TODO: mover a hooks
  const borrarTurno = useCallback(
    (turnoId) => {
      TurnosAPI.deleteTurno(turnoId)
        .then((response) => {
          alert.show(`${t("misReserva>delete>turnDeleted")}`);
          setLoading(true);
          getTurnosActivos();
          getTurnosCocherasActivos();
        })
        .catch(function (error) {
          if (!error.response) alert.show("" + error);
          else alert.show("" + error.response.data.error);
        });
    },
    [alert, t, getTurnosActivos, getTurnosCocherasActivos]
  );

  //TODO: mover a hooks
  const clickDelete = useCallback(
    (index) => {
      swal(
        <p>
          Estas seguro de eliminar el turno para{" "}
          {turnosActivosData[index].Edificio} con fecha{" "}
          {turnosActivosData[index].FechaTurno.substr(0, 10)}?
        </p>,
        {
          icon: "warning",
          buttons: {
            yes: {
              text: t("misReserva>text>yes"),
              value: "si",
            },
            no: {
              text: t("misReserva>text>no"),
              value: "no",
            },
          },
        }
      ).then((value) => {
        if (value === "si") {
          borrarTurno(turnosActivosData[index].TurnoId);
        }
        return false;
      });
    },
    [borrarTurno, t, turnosActivosData]
  );

  //TODO: mover a componente externo
  const RowA = ({ index, style }) => (
    //turnosActivosData[index].ConEstacionamiento
    <div
      style={{
        fontWeight: "bold",
        textAlign: "center",
        borderColor: "black",
        borderWidth: 1,
      }}
    >
      <Button>
        <TodayIcon
          style={{
            color: "rgb(34, 87, 138)",
            height: "20px",
            width: "20px",
            marginRight: "10px",
          }}
        />
        {turnosActivosData[index] &&
          turnosActivosData[index].ConEstacionamiento == true && (
            <DriveEtaIcon
              style={{
                color: "rgb(34, 87, 138)",
                height: "20px",
                width: "20px",
                marginRight: "10px",
              }}
            />
          )}
        {turnosActivos[index]}
        <DeleteIcon
          onClick={() => {
            clickDelete(index);
          }}
          style={{
            color: "#ff6961",
            height: "20px",
            width: "20px",
            marginLeft: "4px",
          }}
        />
      </Button>
      <Divider />
    </div>
  );
  //TODO: mover a componente externo
  const RowH = ({ index, style }) => (
    <div
      style={{
        fontWeight: "bold",
        textAlign: "center",
        borderColor: "black",
        borderWidth: 1,
      }}
    >
      <Button>
        <TodayIcon
          style={{
            color: "rgb(134, 87, 80)",
            height: "20px",
            width: "20px",
            marginRight: "10px",
          }}
        />
        {turnosHistoricos[index]}
      </Button>
      <Divider />
    </div>
  );
  //TODO: mover a componente externo
  const RowC = ({ index, style }) => (
    <div
      style={{
        fontWeight: "bold",
        textAlign: "left",
        borderColor: "black",
        borderWidth: 1,
      }}
    >
      <Button
        style={{
          width: "100%",
          // @ts-ignore
          textAlign: "left !important",
        }}
      >
        <DirectionsCar
          style={{
            color: "rgb(34, 87, 138)",
            height: "20px",
            width: "20px",
            marginRight: "10px",
          }}
        />
        {turnosCocheraActivos[index]}
        {/* <DeleteForeverIcon onClick={() => { clickDelete(index) }} style={{ color: "rgb(100, 5, 5)", height: '20px', width: '20px', marginLeft: '4px' }} /> */}
      </Button>
      <Divider />
    </div>
  );
  useEffect(() => {
    setLoadingPermisos(true);
    var constraints = { video: true };
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((permisson) => {
        console.log("El usuario activo la camara", permisson);
        // @ts-ignore
        setTienePermisosCamara(permisson.active);
        setLoadingPermisos(false);
      })
      .catch((err) => {
        console.log("No se pudo activar la camara" + err);
        setLoadingPermisos(false);
      });
  }, []);
  const handleDateChange = (fechaSeleccionada) => {
    let fechaElegida = fechaSeleccionada.toString();
    let mesActual = new Date().getMonth();
    let mesFechaElegida = new Date(fechaElegida).getMonth();
    let reservasEnElMismoDia = 0;
    let auxReservasSimultaneasPorFecha = [];

    if (mesFechaElegida < mesActual)
      return console.trace(
        "No podes cancelar fechas mas viejas al dia de la fecha"
      );

    auxHighLightWithRanges.forEach((turno, index) => {
      if (
        turno.fecha.toString().substring(4, 15) ===
        fechaElegida.substring(4, 15)
      ) {
        reservasEnElMismoDia++;
        auxReservasSimultaneasPorFecha.push(turno);
      }
    });

    if (reservasEnElMismoDia > 0) {
      setReservasSimultaneasPorFecha([...auxReservasSimultaneasPorFecha]);
      setShowMultiplesReservasModal(true);
      reservasEnElMismoDia = auxReservasSimultaneasPorFecha.length;
      return console.trace("DEBERIA ABRIR EL MODAL");
    }
  };

  return (
    <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
      <div>
        {showMultiplesReservasModal ? (
          <ShowMultiplesReservasModal
            show={() =>
              setShowMultiplesReservasModal(!showMultiplesReservasModal)
            }
            turnosMultiples={reservasSimultaneasPorFecha}
            borrarTurno={borrarTurno}
          />
        ) : (
          <></>
        )}
        <Header />
        <Sidebar />

        {loading ? (
          <MySpinner />
        ) : (
          <div>
            <Container maxWidth="sm">
              <>
                <br />
                <h1
                  className="ExpertaText"
                  style={{ marginTop: 0, marginBottom: 20 }}
                >
                  {t("misReserva>title>reservations")}
                </h1>

                {!diagnostivoActivo ? (
                  <p style={{ color: "red", fontSize: 20, marginBottom: 40 }}>
                    {t("misReserva>text>diagVencido")}
                  </p>
                ) : sospechoso ? (
                  <p style={{ color: "red", fontSize: 20, marginBottom: 40 }}>
                    {t("misReserva>text>sospechoso")}
                  </p>
                ) : (
                  <>
                    <Button
                      style={{
                        alignSelf: "center",
                        textTransform: "none",
                        textAlign: "center",
                        backgroundColor: "#2f4050",
                        color: "white",
                      }}
                      variant="contained"
                      type="submit"
                      onClick={clickQR}
                    >
                      {t("misReserva>button>qrIncome")}
                    </Button>
                    <p style={{ margin: 20 }}>o</p>
                    <Button
                      style={{
                        alignSelf: "center",
                        textTransform: "none",
                        textAlign: "center",
                        backgroundColor: !tienePermisosCamara
                          ? "#FF6B6B"
                          : "#2f4050",
                        color: "white",
                      }}
                      variant="contained"
                      type="submit"
                      onClick={
                        tienePermisosCamara
                          ? () => history.push("/LectorQROficina")
                          : () => {
                              console.log("No tenes permisos");
                            }
                      }
                    >
                      {!tienePermisosCamara
                        ? t("misReserva>text>permission")
                        : t("misReserva>button>qrValidarReserva")}
                    </Button>
                    <p style={{ margin: 20 }}>
                      {t("misReserva>paragraph>permissionLeyend")}
                    </p>
                  </>
                )}

                {/* <Button
                style={{ alignSelf: "center", textTransform: "none", textAlign: "center", backgroundColor: "#2f4050", color: "white" }}
                variant="contained"
                type='submit' onClick={() => history.push('/LectorQRUsuario')}>
                {t("misReserva>button>qrScan")}
              </Button> */}

                <p className="HomeDescr" style={{ marginTop: 25 }}>
                  {t("misReserva>paragraph>activeReserver")}
                </p>
                <div className="contenedor-leyendas">
                  <LeyendaColores text={"Oficina"} color="#77dd77" />
                  <LeyendaColores text={"Estacionamiento"} color="#2a87d0" />
                  <LeyendaColores text={"Multiples Reservas"} color="#fdfd96" />
                  <LeyendaColores
                    text={"Reservas Historicas"}
                    color="#fdcae1"
                  />
                </div>
                <Collapse />
                <div id="datePicker">
                  <DatePicker
                    // @ts-ignore
                    style={style.datePicker}
                    id="fecha"
                    locale={i18n.language}
                    name="fecha"
                    placeholderText={t("reserva>titlelabel>choseDate")}
                    onChange={(date) => handleDateChange(date)}
                    endDate={setMinutes(addDays(new Date(), 30), 30)}
                    dateFormat="MMMM d, yyyy"
                    inline
                    highlightDates={highLightWithRanges}
                    showDisabledMonthNavigation
                  />
                </div>

                {/* <ListaTurnosActivos turnosActivos={turnosActivos} RowA={RowA}/> */}
                {/* <ListaTurnosCocheraActivos turnosCocheraActivos={turnosCocheraActivos} RowC={RowC} /> */}

                <p
                  className="HomeDescr"
                  style={{
                    marginTop: 10,
                    marginBottom: 25,
                    cursor: "pointer",
                    textAlign: "center",
                  }}
                  onClick={() =>
                    setShowReservasHistoricas(!showReservasHistoricas)
                  }
                >
                  {t("misReserva>paragraph>historialReserver")}{" "}
                  <ArrowDropDownCircle />
                </p>
                {showReservasHistoricas ? (
                  turnosHistoricos.map((turno, index) => {
                    console.log(typeof turno);
                    return (
                      <Button
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "self-start",
                          justifyContent: "space-between",
                        }}
                      >
                        <TodayIcon color="action" />
                        <p>{turno}</p>
                      </Button>
                    );
                  })
                ) : (
                  <></>
                )}
                <br />
              </>
            </Container>
          </div>
        )}
      </div>
    </Sentry.ErrorBoundary>
  );
};

export default MisReservas;
