import * as Yup from 'yup';

export const validationGerenciaPiso = Yup.object({
    IdPiso: Yup.number()
        .required('Requerido'),
    Cupo: Yup.number()
        .required('Requerido'),
    IdGerencia: Yup.number()
        .required('Requerido'),
})

export const validationHorario = Yup.object({
    IdEdificio: Yup.number()
        .required('Requerido'),
    Cupo: Yup.number()
        .required('Requerido'),
    Horario: Yup.string()
        .required('Requerido')
})

export const validationGerencia = Yup.object({
    Nombre: Yup.string()
        .required('Requerido')
})

export const validationNuevoEdificio = Yup.object({
    nombre: Yup.string()
        .max(30, 'No puede tener más de 30 caracteres')
        .required('Requerido'),
    direccion: Yup.string()
        .required('Requerido'),
    // lat: Yup.string()
    //     .required('Requerido'),
    // long: Yup.string()
    //     .required('Requerido'),
    cupoEstacionamiento: Yup.number()
        .required('Requerido'),
})


export const validationNuevoPiso = Yup.object({
    Nombre: Yup.string()
        .max(30, 'No puede tener más de 30 caracteres')
        .required('Requerido'),
    Numero: Yup.number()
        .required('Requerido'),
    idEdificio: Yup.number()
        .required('Requerido'),
})
export const validationEditarPiso = Yup.object({
    Nombre: Yup.string()
        .max(30, 'No puede tener más de 30 caracteres')
        .required('Requerido'),
    Piso: Yup.number()
        .required('Requerido'),
    IdPiso: Yup.number()
        .required('Requerido'),
        
})

export const validationPisoGerencia = Yup.object({
    Cupo: Yup.number()
        .required('Requerido'),
    IdGerencia: Yup.number()
        .required('Requerido'),
    IdPiso: Yup.number()
        .required('Requerido'),
})

export const validationNuevoUsuario = Yup.object({
    DNI: Yup.number()
        //.test('len', 'El dni no es válido ', val => val && val.toString().length >= 7)
        .required('Requerido'),
    Nombre: Yup.string()
        .max(30, 'No puede tener más de 30 caracteres')
        .required('Requerido'),
    email: Yup.string()
    //.email('El correo no tiene un formato válido')
    //.required('Requerido')
    ,
    idTipoDeUsuario: Yup.string()
        .required('Requerido'),
    idGerencia: Yup.string()
    //.required('Requerido')
    ,
    idJefeDirecto: Yup.string(),
    Activo: Yup.boolean(),
    Password: Yup.string()
        .required('Requerido'),
    Password2: Yup.string()
        .oneOf([Yup.ref('Password')], 'Las contraseñas no son iguales')
        .required('Requerido')
})

export const validacionEditarUsuario = Yup.object({
    Nombre: Yup.string()
        .max(30, 'No puede tener más de 30 caracteres')
        .required('Requerido'),
    DNI: Yup.number(),
    IdTipoDeUsuario: Yup.string()
        .required('Requerido'),
    IdGerencia: Yup.string()
        .nullable(),
    Activo: Yup.boolean()
})

export const validationPais = Yup.object({
    Nombre: Yup.string()
        .required('Requerido')
})

export const validationTipoDeUsuario = Yup.object({
    Descripcion: Yup.string()
        .required('Requerido')
})

export const validationVacuna = Yup.object({
    Nombre: Yup.string()
        .required('Requerido')
})